import React from 'react'

function AttorneyDashboard() {
  return (
    <>
        <div className="col-lg-6 m-auto">
            <h6 className="mb-2 font-weight-bold pl-2">Dashboard</h6>
        </div>
        <div className="col-lg-6 bg-white mb-3 p-4 m-auto border">
            <div className="row ">
                <div className="col-xl-12 col-md-6 mb-4 border-left">

                    <div className="row" style={{ fontWeight: '500' }}>
                        <div className="col-md-6 border-right">

                            <ul className="list-group ">
                                <a href="attorney/tickets"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Tickets</div> Create,View and manage tickets
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}></span>
                                </a>
                                <a href="attorney/claimedtickets"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">My Tickets</div> Create,View and manage tickets
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}></span>
                                </a>
                                <a href="attorney/opentickets"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Open Tickets</div> Create,View and manage tickets
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}></span>
                                </a>
                                <a href="attorney/closedtickets"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Closed Tickets</div> Create,View and manage tickets
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}></span>
                                </a>
                                <a href="attorney/premiumtickets"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Premium processing</div> View and manage premium processing cases
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}></span>
                                </a>
                                <a href="attorney/regulartickets"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Regular Processing</div> View and manage Regular processing cases
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}></span>
                                </a>
                            </ul>
                        </div>
                        <div className="col-md-6 border-right">

                            <ul className="list-group">
                                <a href="attorney/publicDocuments"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Public access files</div> View and mange public access files
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}></span>
                                </a>
                                <a href="attorney/uscisDocuments"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">USCIS documentation</div> View and mange documents submitted to
                                        USCIS
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}></span>
                                </a>
                                <a href="attorney/sampleDocuments"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Sample Documents</div> View and mange sample documents
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}></span>
                                </a>
                                <a href="attorney/lca"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">LCA</div> Create,View and manage LCA
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}></span>
                                </a>
                                <a href="#"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Reports</div> View and download reports
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}></span>
                                </a>
                                <a href="#"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">My News</div> View and download reports
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}></span>
                                </a>

                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default AttorneyDashboard