import React,{useEffect, useState} from 'react'
import Doc from '../images/doc.png'
import Modal from 'react-bootstrap/Modal';
import AuthUser from "../AuthUser";
function Performancereview() {
    const [values,setValues]=useState({year:'',formqtr:'',document:''});
    const [errors, setErrors] = useState({year:'',formqtr:'',document:''});
    const [message,setMessage]= useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    //Date 
    const now = new Date();
    const current = now.getFullYear();
    const month= now.getMonth();
    const previous = current - 1;
    // End Date
    // Fetch Performance Review 
    const [cperformance, setCprerformance] = useState([]);
    const [viewprerformance, setViewprerformance] = useState([]);
    function fetchItems(){
        http.get(`applicant/performancereview/${userdetails.email}`)
        .then((response) => {
            console.log(response.data);
            setViewprerformance(response.data.previousreview);
            setCprerformance(response.data.performancereview);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    useEffect(() => {
        fetchItems();
        
    }, []);
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Subject
        if (!values.year.trim()) {
            newErrors.year = 'Year is required';
            isValid = false;
        } else {
            newErrors.year = '';
        }
        // Message
        if (!values.formqtr.trim()) {
            newErrors.formqtr = 'Quarter is required';
            isValid = false;
        } else {
            newErrors.formqtr = '';
        }
        if (!values.document.trim()) {
            newErrors.document = 'Document is required';
            isValid = false;
        } else {
            newErrors.document = '';
        }
        setErrors(newErrors);
        setMessage('');
        return isValid;
    };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let year = values.year;
        let formqtr = values.formqtr;
        let document = e.target.document.files[0];
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`applicant/performanceform/${userdetails.email}`,{year:year,formqtr:formqtr,file_name:document,userid:userdetails.id}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              setValues({
                year: '',
                formqtr: '',
                document: '',
                
              });
              e.target.reset();
              setMessage('Performance review sent successfully!');
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.year = error.response.data?.error?.year;
                newErrors.formqtr = error.response.data?.error?.formqtr;
                newErrors.document = error.response.data?.error?.document;
                newErrors.message = error.response.data?.error?.message;
                setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
    
  return (
    <div className="row p-3">
        <div className="col-md-6">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb p-0">
                    <li className="breadcrumb-item "><a href="/applicant">Dashboard</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Performance Review</li>
                </ol>
            </nav>
        </div>
        <div className="row ">
            <div className="col-md-5 small m-auto text-center sm-doc">
                <div className=" col-md-4 i m-auto">
                    <div className="c">
                        <div className="wrap p-2">
                            <div className="col-md-12 text-center mt-2" style={{ height: '100px' }}>
                                <img src={Doc} alt="doc" />
                                <p></p>
                            </div>
                            <div className="text-right small mt-3"></div>
                        </div>
                        <div className="more">
                            <div className="d-grid gap-2 mb-2">
                                                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" className="btn btn-primary rounded-0 mt-4" onClick={handleShow}>Submit Performance Review Form <i className="bi bi-arrow-right-short"></i></button><br/>
                <a type="button" className="btn  text-white rounded-0 mt-2" style={{ backgroundColor: '#719F42' }} href="/applicant/viewperformancereview">View all performance review forms <i className="bi bi-arrow-right-short"></i></a>
            </div>
        </div>
        <div className="row mt-4">
            <div className="col-md-5 small m-auto">
                <h5><strong>Performance Review Form </strong></h5>
            </div>
        </div>
        <div className="row mt-2">
            <div className="col-md-5 m-auto rounded-0 border p-2">
                <ul className="list-group rounded-0">
                    <li className="list-group-item justify-content-between align-items-center" style={{ display: (month < '12') ? 'none' : 'block' }}>
                        Q4 (Oct to Dec {current}) 
                        {(cperformance != null && cperformance.Q4 !== 'NULL') ? 
                            <span className="badge rounded-pill p-1 text-success " style={{ border: '2px solid #719f42' }}>
                                <span >
                                    Submited
                                </span>
                            </span> 
                            : 
                            <span className="badge rounded-pill p-1 text-danger " style={{ border: '2px solid #E61414' }}>
                                <span className="blink">
                                    Overdue
                                </span>
                            </span>
                            } 
                    </li>
                    <li className="list-group-item justify-content-between align-items-center" style={{ display: (month < '12') ? 'none' : 'block' }}>
                        Q3 (Jul to Sep {current})                         
                        {(cperformance != null && cperformance.Q3 !== 'NULL') ? 
                        <span className="badge rounded-pill p-1 text-success " style={{ border: '2px solid #719f42' }}>
                            <span >
                                Submited
                            </span>
                        </span> 
                        : 
                        <span className="badge rounded-pill p-1 text-danger " style={{ border: '2px solid #E61414' }}>
                            <span className="blink">
                                Overdue
                            </span>
                        </span>
                        } 
                    </li>
                    <li className="list-group-item  d-flex  justify-content-between align-items-center" style={{ display: (month < '12') ? 'none' : 'block' }}>
                        Q2 (Apr to Jun {current})                         
                        {(cperformance != null && cperformance.Q2 !== 'NULL') ? 
                        <span className="badge rounded-pill p-1 text-success " style={{ border: '2px solid #719f42' }}>
                            <span >
                                Submited
                            </span>
                        </span> 
                        : 
                        <span className="badge rounded-pill p-1 text-danger " style={{ border: '2px solid #E61414' }}>
                            <span className="blink">
                                Overdue
                            </span>
                        </span>
                        }
                    </li>
                    <li className="list-group-item  d-flex  justify-content-between align-items-center" style={{ display: (month < '12') ? 'none' : 'block' }}>
                        Q1 (Jan to Mar {current})                         
                        {(cperformance != null && cperformance.Q1 !== 'NULL') ? 
                        <span className="badge rounded-pill p-1 text-success " style={{ border: '2px solid #719f42' }}>
                            <span >
                                Submited
                            </span>
                        </span> 
                        : 
                        <span className="badge rounded-pill p-1 text-danger " style={{ border: '2px solid #E61414' }}>
                            <span className="blink">
                                Overdue
                            </span>
                        </span>
                        }
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center bg-light" style={{ display: (month < '10') ? 'none' : 'block' }}>
                        Q4 (Oct to Dec {previous})                         
                        {(viewprerformance != null && viewprerformance.Q4 !== 'NULL') ? 
                        <span className="badge rounded-pill p-1 text-success " style={{ border: '2px solid #719f42' }}>
                            <span >
                                Submited
                            </span>
                        </span> 
                        : 
                        <span className="badge rounded-pill p-1 text-danger " style={{ border: '2px solid #E61414' }}>
                            <span className="blink">
                                Overdue
                            </span>
                        </span>
                        }
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center" style={{ display: (month < '7') ? 'none' : 'block' }}>
                        Q3 (Jul to Sep {previous})
                        {(viewprerformance != null && viewprerformance.Q3 !== 'NULL') ? 
                        <span className="badge rounded-pill p-1 text-success " style={{ border: '2px solid #719f42' }}>
                            <span >
                                Submited
                            </span>
                        </span> 
                        : 
                        <span className="badge rounded-pill p-1 text-danger " style={{ border: '2px solid #E61414' }}>
                            <span className="blink">
                                Overdue
                            </span>
                        </span>
                        }
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center" style={{ display: (month < '3') ? 'none' : 'block' }}>
                        Q2 (Apr to Jun {previous})
                        {(viewprerformance != null && viewprerformance.Q2 !== 'NULL') ? 
                        <span className="badge rounded-pill p-1 text-success " style={{ border: '2px solid #719f42' }}>
                            <span >
                                Submited
                            </span>
                        </span> 
                        : 
                        <span className="badge rounded-pill p-1 text-danger " style={{ border: '2px solid #E61414' }}>
                            <span className="blink">
                                Overdue
                            </span>
                        </span>
                        }
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center" >
                        Q1 (Jan to Mar {previous})
                        {(viewprerformance != null && viewprerformance.Q1 !== 'NULL') ? 
                        <span className="badge rounded-pill p-1 text-success " style={{ border: '2px solid #719f42' }}>
                            <span >
                                Submited
                            </span>
                        </span> 
                        : 
                        <span className="badge rounded-pill p-1 text-danger " style={{ border: '2px solid #E61414' }}>
                            <span className="blink">
                                Overdue
                            </span>
                        </span>
                        }
                    </li>
                </ul>
            </div>
        </div>
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter"><span className="pl-2"> <i className="bi bi-file-earmark-word"></i>Submit Performance Review Form </span></Modal.Title>
            </Modal.Header>
            <form method="POST" onSubmit={handleSubmit}>
            <Modal.Body className='modal-body'>                
            <div className="col-md-12 p-2 border customer_records" style={{ backgroundColor: '#F6F6F6' }}>
                        <div className="mb-3">
                            <div className="row mt-2">
                                <div className="col-md-6">
                                    <select className="form-select rounded-0" name="year" onChange={handleChange} required="" aria-label="select example">
                                        <option value="">Select Year *</option>
                                        <option value={current}>{current}</option>
                                        <option value={previous}>{previous}</option>
                                    </select>
                                    <div className="invalid-feedback small">{errors.year}</div>
                                </div>
                                <div className="col-md-6">
                                    <select name="formqtr" className="form-select rounded-0" onChange={handleChange} aria-label="select example">
                                        <option value="">Select quarter *</option>
                                        <option value="Q1">Q1 (Jan to Mar)</option>
                                        <option value="Q2">Q2 (Apr to Jun)</option>
                                        <option value="Q3">Q3 (Jul to Sep)</option>
                                        <option value="Q4">Q4 (Oct to Dec)</option>
                                    </select>
                                    <div className="invalid-feedback small">{errors.formqtr}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt-4">
                                    <div className="form-group">
                                        <input className="form-control file" type="file" name="document"  accept=".docx,application/pdf" id="formFile" onChange={handleChange} />
                                        <div className="invalid-feedback small">{errors.document}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="alert-success small">{message}</div>
                        </div>
                    </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-2 col-md-12 mt-5 text-center">
                    <button type="reset" className="btn btn-secondary ml-2" data-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary ml-2" onClick={validateForm}>Submit</button>
                </div>
            </Modal.Footer>
            </form>
        </Modal>
    </div>
  )
}

export default Performancereview