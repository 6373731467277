import React, { useState, useEffect } from 'react'
import AuthUser from "../AuthUser";
function Casenotes(props) {
  const [casenotes, setCasenotes] = useState([]);
  const { http, getUser } = AuthUser();
  let usertype = props.logintype;
  let apiurl = props.apiurl;
  let userdetails = getUser();
  
  useEffect(() => {
    if(props.id !== ''){
        http.get(`${usertype}/${apiurl}/${props.id}`)
        .then((response) => {
            console.log(response.data);
            setCasenotes(response.data.ticketslist);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    
}, [props.id]);
  return (
    <>
      <table className="col-md-12 table-striped">
          <thead>
              <tr>
                  <td colSpan={2}>User Name</td>
                  <td>Ticket</td>
                  <td>Processing Type</td>
                  <td>Task Performed</td>
                  <td>Date Time stamp</td>
              </tr>
          </thead>
          <tbody>
            { casenotes.map(casenote => (
              <tr key={casenote.id}>
                  <td colSpan={2}>{ casenote.name }</td>
                  <td>{ casenote.ticket }</td>
                  <td>{ casenote.processing_type }</td>
                  <td>{ casenote.task_performed }</td>
                  <td>{ casenote.created_at }</td>
              </tr>
                
            )) }   
          </tbody>
      </table>
    </>
  )
}

export default Casenotes;