import React, { useState,useEffect } from 'react';
import AuthUser from '../AuthUser';
function Editprofile(props) {
  let usertype = props.logintype;
    const {http,getUser,setUser} = AuthUser();
    let userdetails = getUser();
    const [country,setCountry]=useState({});
    const [states,setStates]=useState([]);
    const [userdata,setUserdata]=useState({});
    const [values,setValues]=useState({name:'',lastname:'',phone:'',country:'',state:''});
    const [errors, setErrors] = useState({name:'',lastname:'',phone:'',country:'',state:''});
    const [message, setMessage] = useState();
    const [submitted, setsubmitted] = useState(false);
    useEffect(() => {
        http.get(`${usertype}/editprofile/${userdetails.email}`)
        .then((response) => {
            console.log(response.data);
            setStates(response.data.states);
            setCountry(response.data.country);
            setUserdata(response.data.userdata)
            setValues(response.data.userdata);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }, []);
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const alphaRegex = /^[A-Za-z]+$/;
        const numRegex = /^[0-9\b]+$/;
        // Validate First Name
        if (!values.name.trim()) {
            newErrors.name = 'First Name is required';
            isValid = false;
        } else if(!alphaRegex.test(values.name)){
            newErrors.name='Only Characters allowed';
            isValid = false;
        }else {
            newErrors.name = '';
        }

        if (!values.lastname.trim()) {
            newErrors.lastname = 'Last Name is required';
            isValid = false;
        } else if(!alphaRegex.test(values.lastname)){
            newErrors.name='Only Characters allowed';
            isValid = false;
        }else {
            newErrors.lastname = '';
        }
        if (!values.phone.trim()) {
            newErrors.phone = 'Phone number is required';
            isValid = false;
        } else if(!numRegex.test(values.phone)){
            newErrors.phone='Only numbers allowed';
            isValid = false;
        }else {
            newErrors.lastname = '';
        }
        if (!values.country) {
            newErrors.country = 'Country is required';
            isValid = false;
        } else {
            newErrors.country = '';
        }

        if (!values.state) {
            newErrors.state = 'State is required';
            isValid = false;
        } else {
            newErrors.state = '';
        }

        setErrors(newErrors);
        return isValid;
    };
      
    function handleChange(e){
        setValues({...values,[e.target.name]:e.target.value})
    }
      
    const handleSubmit = async (e) =>{
        e.preventDefault();
        //validation(values);
        let name = values.name;
        let lastname = values.lastname;
        let country = values.country;
        let state = values.state;
        let phone = values.phone;
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`${usertype}/editprofile`,{name:name,lastname:lastname,phone:phone,email:userdetails.email,country:country,state:state}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              //console.log(response.data);
              setUser(response.data.userdata);
              setMessage('Profile updated successfully !');
              
          }).catch(function (error) {
            // handle error
            console.log(error);
            //console.log(error.response.data.error);
            
            if(error?.response?.status==403){
              newErrors.email = error.response.data?.error?.email;
              newErrors.password = error.response.data?.error?.password;
              setErrors(newErrors);
            }
            if(error.response.status==401){
              newErrors.message = error.response.data?.error;
              setErrors(newErrors);
            }
            
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
  return (
    <div class="container-fluid"> 
      <div class="col-md-6 m-auto p-0 pb-2 ">
          <h6 class="mb-2 font-weight-bold">Edit Profile</h6>
      </div>
      <div class="col-md-6 bg-white m-auto shadow-sm p-4" style={{ borderTop: '5px solid #DF8E2E' }}>
          <form method="POST" onSubmit={handleSubmit}>              
              <div class="row">
                  <div class="mb-2 col-md-6">
                      <label  class="form-label small" style={{ color: '#2D8BB4' }}><strong>First name</strong></label>
                      <input class="form-control textOnly" type="text" placeholder="First name" name="name" value={ values.name } onChange={handleChange}/>
                      <div className="invalid-feedback small">{errors.name}</div>
                  </div>
                  <div class="mb-2 col-md-6">
                      <label  class="form-label small" style={{ color: '#2D8BB4' }}><strong>Last name</strong></label>
                      <input class="form-control textOnly" type="text" placeholder="Last name" name="lastname" value={ values.lastname } onChange={handleChange} />
                      <div className="invalid-feedback small">{errors.lastname}</div>
                  </div>
                  <div class="mb-2 col-md-6">
                      <label  class="form-label small" style={{ color: '#2D8BB4' }}><strong>Phone number</strong></label>
                      <input type="phone" id="phone" name="phone" class="form-control rounded-0 " maxlength="13" value={ values.phone } onChange={handleChange} placeholder="Mobile Number" />
                      <div className="invalid-feedback small">{errors.phone}</div>
                  </div>
                  <div class="mb-2 col-md-6">
                      <label  class="form-label small" style={{ color: '#2D8BB4' }}><strong>Email</strong></label>
                      <input class="form-control" type="Email" placeholder="Email" name="email" readonly="true" value={ values.email } onChange={handleChange} />
                      <div className="invalid-feedback small">{errors.email}</div>
                  </div>

                  <div class="mb-2 col-md-6">
                      <label  class="form-label small" style={{ color: '#2D8BB4' }}><strong>Country</strong></label>
                      <select class="form-select rounded-0 mt-2" id="country" name="country" value={values.country} onChange={handleChange} aria-label="Default select example">
                          <option value="">Select Country *</option>
                          <option value={country.id} selected={country.id === values.country }>{country.name}</option>
                      </select>
                      <div className="invalid-feedback small">{errors.country}</div>
                  </div>
                  <div class="mb-2 col-md-6">
                      <label  class="form-label small" style={{ color: '#2D8BB4' }}><strong>State</strong></label>
                      <select class="form-select rounded-0 mt-2" id="state" name="state" value={values.state}  onChange={handleChange} aria-label="Default select example">
                            <option value="">Select State *</option>
                            { states.map(state =>(
                                <option selected={state.id === values.state } value={state.id}>{state.name}</option>
                            ))}
                      </select>
                      <div className="invalid-feedback small">{errors.state}</div>
                  </div>
                  <div className="alert-success small">{message}</div>
                  <div class="col-12 mt-3 text-center">
                      <button class="btn text-white btn-secondary" type="button">Cancel</button>&nbsp;
                      <button class="btn text-white" type="submit" style={{ backgroundColor: '#81A744' }} onClick={validateForm} disabled={submitted} >Update</button>
                  </div>
              </div>
          </form>
      </div>
    </div>
  )
}

export default Editprofile