import React from 'react'

function Footer() {
  return (
    <footer className="site-footer mt-3 text-center p-2 copyright-text fixed-bottom">
        <div className="col-md-6 m-auto"> © 2023 H1BAPPLICANTS.COM </div>
    </footer>
  )
}

export default Footer