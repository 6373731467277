import React, { useState, useEffect } from 'react'
import AuthUser from "../AuthUser";
import Casenotes from './Casenotes';
import Table from 'react-bootstrap/Table';
import Dropdown from "react-bootstrap/Dropdown";
function Ticketslist(props) {
    
    let usertype = props.logintype;
    
    let pageview = props.pageview;
    let dtype= pageview === 'Tickets' ? 'tickets' : pageview === 'Premium Tickets' ? 'premium' : pageview === 'Regular Tickets' ? 'regular' : 'tickets';
    let apiurl = props.apiurl;
    let dashboard=`/${usertype}`;
    const [ticketslist, setTicketslist] = useState([])
    const [adminticketslist, setAdminticketslist] = useState([])
    const [ticketscount, setTicketscount] = useState(0)
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    const [visibleDivs, setVisibleDivs] = useState([]);

    const toggleDivVisibility = (divId) => {
      if (visibleDivs.includes(divId)) {
        // If the div is already visible, hide it
        setVisibleDivs(visibleDivs.filter(id => id !== divId));
      } else {
        // If the div is not visible, show it
        setVisibleDivs([...visibleDivs, divId]);
      }
    };
    function fetchItems(){
        http.get(`${usertype}/${apiurl}/${userdetails.email}`)
        .then((response) => {
            console.log(response.data);
            setTicketslist(response.data.ticketslist);
            setAdminticketslist(response.data.admintickets);
            setTicketscount(response.data.ticketslist.length)
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    useEffect(() => {
        fetchItems();
    }, []);
    //Attorney Claim Ticket status
    const handleStatus = (ticketid) =>{
        http.get(`/attorney/chageCliamTicket?ticketid=${ticketid}&statusval=Y&userType=AT&userId=${userdetails.id}`)
        .then((response) => {
            fetchItems();
            
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    const applyTitle = (val, id) =>{
        http.get(`/${usertype}/chageTicketTitle?ticketid=${id}&title=${val}&userType=E&userId=${userdetails.id}`)
        .then((response) => {
            fetchItems();
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    const applyStatus = (val, id) =>{
        http.get(`/employer/chageStatuTicket?ticketid=${id}&statusval=${val}&userType=E&userId=${userdetails.id}`)
        .then((response) => {
            fetchItems();
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    const applyMyStatus = (e,id) =>{
        http.get(`/attorney/chageStatuTicket?ticketid=${id}&statusval=${e}&userType=AT&userId=${userdetails.id}`)
        .then((response) => {
            fetchItems();
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    const applyType = (val, id) =>{
        let ut = usertype === 'employer' ? 'E' : 'AT';
        http.get(`/${usertype}/chageTicketType?ticketid=${id}&statusval=${val}&userType=${ut}&userId=${userdetails.id}`)
        .then((response) => {
            fetchItems();
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
          href=""
          ref={ref}
          onClick={e => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {children}
          <span className="threedots" />
        </a>
      ));
  return (
    <div className="container-fluid">
        <div className="row mb-2 ">
            <div className="col-md-6">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item "><a href={dashboard}>Dashboard</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{pageview}</li>
                    </ol>
                </nav>
            </div>
            <div className="col-md-4 small  text-right">
                <div className="row">
                    
                </div>
            </div>
            <div className="col-md-2 small  text-right">
                <h6 className="font-weight-bold">{pageview} :<span className="text-primary">{ticketscount}</span></h6>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 bg-white shadow-sm pb-5">
                <div className="card  mb-4">
                    <div className="card-body">
                        <div className="table-responsive">
                        
                        <Table className="Tabla table table-hover table-condensed">
                            <thead>
                                <tr>
                                    <th data-sortable="true">Ticket By</th>
                                    <th>Ticket For</th>
                                    <th>Processing Type</th>
                                    <th className="text-center"><i className="bi bi-paperclip"></i> </th>
                                    <th>Status</th>
                                    <th data-sortable="true">Submitted Date / Time</th>
                                    {usertype!=='attorney' && <th data-sortable="true">Case Notes</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {ticketslist.map(ticket => (
                                <>
                                    <tr key={ticket.id}>
                                        <td>
                                            { ticket.name}&nbsp;{ ticket.lastname}
                                        </td>
                                        <td>
                                            <div className="row">
                                                <div className="col-9" > { ticket.ticket}</div>
                                                <div className="col-3">
                                                    {(usertype==='employer'  && apiurl === 'ticketslist') ?
                                                    <Dropdown>
                                                        <Dropdown.Toggle as={CustomToggle} />
                                                        <Dropdown.Menu size="sm" title="">
                                                            { adminticketslist.map(adminticket =>(
                                                                <Dropdown.Item href="javascript:void(0);" onClick={() => applyTitle(adminticket.title,ticket.id)} >{adminticket.title}</Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown> 
                                                    : 
                                                    ''
                                                    }
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="row">
                                                <div className="col-9"> { ticket.processing_type}</div>
                                                <div className="col-3">
                                                    <Dropdown>
                                                        <Dropdown.Toggle as={CustomToggle} />
                                                        <Dropdown.Menu size="sm" title="">                                                            
                                                                <Dropdown.Item href="javascript:void(0);" onClick={() => applyType('Premium',ticket.id)} >Premium</Dropdown.Item>
                                                                <Dropdown.Item href="javascript:void(0);" onClick={() => applyType('Regular',ticket.id)} >Regular</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-center"><a href={`mydocs/${ticket.user_id}/${dtype}`} >View Docs</a></td>
                                        <td>
                                            {(usertype==='attorney'  && apiurl === 'ticketslist')
                                                ? 
                                                <a class="btn btn-sm btn-success  " href="#" onClick={() => handleStatus(ticket.id)} > { (ticket.cstatus == 'Y' && ticket.claimed_user == userdetails.id) ? 'Claimed Ticket' : 'Claim Ticket' }</a> 
                                                : (usertype==='employer'  && apiurl === 'ticketslist')
                                                ? <div className="row">
                                                <div className="col-9"> { ticket.status == 'C' ? 'Close' : ticket.status == 'A' ? 'Approve' : 'Open' }</div>
                                                    <div className="col-3">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle} />
                                                            <Dropdown.Menu size="sm" title="">                                                            
                                                                    <Dropdown.Item href="javascript:void(0);" onClick={() => applyStatus('A',ticket.id)} >Approve</Dropdown.Item>
                                                                    <Dropdown.Item href="javascript:void(0);" onClick={() => applyStatus('C',ticket.id)} >Close</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div> 
                                                :
                                                (usertype==='attorney'  && apiurl === 'mytickets')
                                                ? <div className="row">
                                                    <div className="col-12"> 
                                                        <select className="form-select" id="inlineFormSelectPref" value={ticket.status} onChange={e => applyMyStatus(e.target.value,ticket.id)} >
                                                            <option value="">Choose...</option>
                                                            <option selected={ticket.status === 'O' } value="O" >Open</option>
                                                            <option selected={ticket.status === 'W' } value="W" >Work In Progress</option>
                                                            <option selected={ticket.status === 'C' } value="C" >Close</option>                 
                                                        </select>
                                                    </div>                                                    
                                                </div>
                                                :
                                                ticket.status == 'C' ? 'Close' : ticket.status == 'A' ? 'Approve' : 'Open'  }
                                            
                                        </td>
                                        <td>{ ticket.created_at }</td>
                                        <td><a href="#" onClick={() => toggleDivVisibility(ticket.id)} className="accordion-toggle" aria-expanded="true">Case Notes<i className="bi bi-arrow-right-short"></i></a></td>
                                        
                                    </tr>
                                    
                                    <tr style={{ display: visibleDivs.includes(ticket.id) ? 'contents' : 'none'}}>
                                        <td colSpan={7}>                                            
                                        <Casenotes id={visibleDivs.includes(ticket.id) ? ticket.id : ''} logintype={usertype} apiurl="casenotesapi" />
                                        </td>                                    
                                    </tr>
                                </>
                                
                                ))}
                            </tbody>
                        </Table>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
  )
}

export default Ticketslist