import React,{useState,useEffect} from 'react'
import AuthUser from '../AuthUser';
import Modal from 'react-bootstrap/Modal';
function Editlca(props) {
    const { http, getUser } = AuthUser();
    const[getlocations,setGetlocations]=useState([]);
    const [count,setCount] = useState('');
    const[message,setMessage]=useState();
    const [values,setValues]=useState({firstname:'',middlename:'',lastname:'',jobtitle:'',wagelevel:'',jobduties:'',empcount:''});
    const [errors, setErrors] = useState({firstname:'',middlename:'',lastname:'',jobtitle:'',wagelevel:'',jobduties:'',empcount:''});
    let userdetails = getUser();
    function handleChange(e){
        setValues({...values,[e.target.name]:e.target.value})
    }
    const handleOpChange = (index, value) => {
        setGetlocations(prevData => {
            return prevData.map(item => {
              if (item.id === index) {
                return { ...item, ['operating_at']: value }; // Update the specific key-value pair
              }
              return item;
            });
          });
          console.log(getlocations);
    };
    
    const handleClientChange = (index, value) => {
        setGetlocations(prevData => {
            return prevData.map(item => {
              if (item.id === index) {
                return { ...item, ['client_name']: value }; // Update the specific key-value pair
              }
              return item;
            });
          });
    };
    
    const handleWorkChange = (index, value) => {
        setGetlocations(prevData => {
            return prevData.map(item => {
              if (item.id === index) {
                return { ...item, ['work_location']: value }; // Update the specific key-value pair
              }
              return item;
            });
        });
    };  
    const handleIncrement = () => {
        const newValue = parseInt(count) + 1;
        setCount(newValue);
    };

    const handleDecrement = () => {
        const newValue = count - 1;
        setCount(newValue);
    };  
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let emailerror;
        // Validate email
        if (!values.firstname.trim()) {
            newErrors.firstname = 'First Name required';
            isValid = false;
        } else {
            newErrors.name = '';
        }

        setErrors(newErrors);
        return isValid;
    };
    useEffect(() => {
        if(props.id !== ''){
            http.get(`${props.usertype}/showlca?id=${props.id}`)
            .then((response) => {
                console.log(response.data);
                setValues(response.data.showlca);
                setCount(response.data.showlca.empcount);
                setGetlocations(response.data.getlocations);
            })
            .catch(function (error) {
                // handle error
                console.log(error.response.data.error);
            });
        }
        
    }, [props.id]);
    const handleSubmit = async (e) =>{
        e.preventDefault();
        let firstname = values.firstname;
        let middlename = values.middlename;
        let lastname = values.lastname;
        let jobtitle = values.jobtitle;
        let operatingat = '';
        let clientname = '';
        let worklocation = '';
        let locd = getlocations;
        let wagelevel = values.wagelevel;
        let jobduties = values.jobduties;
        let empcount = count;
        if (validateForm()) {
          http.post(`employer/updatelca`,{id:props.id,firstname:firstname,middlename:middlename,lastname:lastname,jobtitle:jobtitle,getlocd:locd,wagelevel:wagelevel,jobduties:jobduties,empcount:empcount,userid:userdetails.id}).then((response)=>{
              
              //console.log(response.data);
              //e.target.reset();
              setMessage('Lca updated successfully!');
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
              //emailerror = error.response.data?.error?.email;
              //setErrors(emailerror);
            }
            if(error.response.status==401){
                //emailerror = error.response.data?.error;
                //setErrors(emailerror);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
  return (
    <>
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={props.show} onHide={props.close} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Edit Lca</Modal.Title>
            </Modal.Header>
            <Modal.Body> 
            <form method="POST" onSubmit={handleSubmit} >
            <div class="row mb-4">
                
                <div class="mb-2 col-md-6">
                    <label class="form-label " style={{ color: '#2D8BB4' }}><strong>FIRST NAME</strong></label>
                    <input class="form-control rounded-0" type="text" name="firstname" value={ values.firstname } id="firstname" onChange={handleChange}placeholder="" />
                </div>
                <div class="mb-2 col-md-6">
                    <label class="form-label " style={{ color: '#2D8BB4' }}><strong>MIDDLE NAME</strong></label>
                    <input class="form-control rounded-0" type="text" name="middlename" value={ values.middlename } id="middlename" onChange={handleChange}  placeholder="" />
                </div>
                <div class="mb-2 col-md-6">
                    <label class="form-label " style={{ color: '#2D8BB4' }}><strong>LAST NAME</strong></label>
                    <input class="form-control rounded-0" type="text" name="lastname" value={ values.lastname } id="lastname" onChange={handleChange} placeholder="" />
                </div>
                <div class="mb-2 col-md-6">
                    <label class="form-label " style={{ color: '#2D8BB4' }}><strong>JOB TITLE</strong></label>
                    <input class="form-control  rounded-0" type="text" name="jobtitle" value={ values.jobtitle } id="jobtitle" onChange={handleChange} placeholder="" />
                </div>
                {getlocations.map((getlcalocation) => (                
                <div key={getlcalocation.id} class="mb-2 col-md-12 mt-2 input_fields_wrap ">
                    <div class="col-md-12 bg-light pt-2 border">
                        <label class="form-label " style={{ color: '#2D8BB4' }}><strong>OPERATING AT THE WORK LOCATION</strong></label>
                        <div class="row">
                            <div class="col-md-2">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name={`operating_at[${getlcalocation.id}]`} onChange={(e) => handleOpChange(getlcalocation.id, e.target.value)} checked={getlcalocation.operating_at ==='Client' ? 'true' : ''} value="Client"  
                                        />
                                    <label class="form-check-label" for="flexRadioDefault1"> Client </label>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name={`operating_at[${getlcalocation.id}]`} onChange={(e) => handleOpChange(getlcalocation.id, e.target.value)} checked={getlcalocation.operating_at ==='Company' ? 'true' : ''}  value="Company"  
                                        />
                                    <label class="form-check-label" for="flexRadioDefault2"> Company </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name={`operating_at[${getlcalocation.id}]`} onChange={(e) => handleOpChange(getlcalocation.id, e.target.value)} checked={getlcalocation.operating_at ==='Wfh' ? 'true' : ''} value="Wfh" 
                                        />
                                    <label class="form-check-label" for="flexRadioDefault3"> Work from Home </label>
                                </div>
                            </div>
                        </div>
                        <label class="form-label"><strong>CLIENT NAME</strong></label>
                        <input class="form-control rounded-0" type="text" placeholder="" name={`client_name[${getlcalocation.id}]`} onChange={(e) => handleClientChange(getlcalocation.id, e.target.value)} value={ getlcalocation.client_name } />
                        <label class="form-label mt-2"><strong>WORK LOCATION 1</strong></label>
                        <textarea class="form-control rounded-0" id="exampleFormControlTextarea1" rows="3" name={`work_location[${getlcalocation.id}]`} onChange={(e) => handleWorkChange(getlcalocation.id, e.target.value)} value={ getlcalocation.work_location }></textarea>
                        
                    </div>
                </div>
                ))}
                <div class="mb-2 col-md-12 mt-2 ">
                    <div class="col-md-12">
                        <label class="form-label " style={{ color: '#2D8BB4' }}><strong>WAGE LEVEL</strong></label>
                        <div class="row">
                            <div class="col-md-2">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="wagelevel" id="wagelevel" value="LEVEL 1" onChange={handleChange} checked={values.wagelevel ==='LEVEL 1' ? 'true' : ''}  />
                                    <label class="form-check-label" for="flexRadioDefault1"> LEVEL 1 </label>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="wagelevel" id="wagelevel" value="LEVEL 2" onChange={handleChange} checked={values.wagelevel ==='LEVEL 2' ? 'true' : ''} />
                                    <label class="form-check-label" for="flexRadioDefault1"> LEVEL 2 </label>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="wagelevel" id="wagelevel" value="LEVEL 3" onChange={handleChange} checked={values.wagelevel ==='LEVEL 3' ? 'true' : ''} />
                                    <label class="form-check-label" for="flexRadioDefault2"> LEVEL 3 </label>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="wagelevel" id="wagelevel" value="LEVEL 4" onChange={handleChange} checked={values.wagelevel ==='LEVEL 4' ? 'true' : ''} />
                                    <label class="form-check-label" for="flexRadioDefault3"> LEVEL 4 </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="wagelevel" id="wagelevel" value="NOT SURE" onChange={handleChange} checked={values.wagelevel ==='NOT SURE' ? 'true' : ''} />
                                    <label class="form-check-label" for="flexRadioDefault3"> NOT SURE </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-2 col-md-12 mt-2">
                    <label class="form-label " style={{ color: '#2D8BB4' }}><strong>JOB DUTIES</strong></label>
                    <div id="loader" class="center"></div>
                    <textarea id="editor" class="form-control rounded-0" onChange={handleChange} rows="3"name="jobduties" value={ values.jobduties } ></textarea>
                </div>
                <div class="mb-2 col-md-12 mt-2">
                    <label class="form-label" style={{ color: '#2D8BB4' }}><strong>EMPLOYEE COUNT AS OF TODAY</strong></label>
                    <div class="qty">
                        <a className="add_field_button btn-primary btn rounded-0" onClick={handleDecrement}> <span className="minus bg-primary">-</span></a>
                        <input type="number" class="count" id="empcount" onChange={handleChange} name="empcount" value={ count } />
                        <a className="add_field_button btn-primary btn rounded-0" onClick={handleIncrement}> <span className="plus bg-primary" >+</span></a>
                    </div>
                    <div className="alert-success small">{message}</div>
                </div>
                <div class="col-12 mt-3 text-center">
                    <button class="btn text-white btn-secondary rounded-0" type="button">Cancel</button>&nbsp;
                    <button class="btn text-white rounded-0" type="submit" style={{ backgroundColor:'#81A744'}} >Update</button>
                </div>
            </div>
            
            </form>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    </>
  )
}

export default Editlca