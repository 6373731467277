import React,{useState,useEffect} from 'react';
import '../css/H1B_Applicants.css';
import '../css/Login.css';
import Login from '../Common/Login';
import Headerlogo from '../images/H1B_Applicants_logo.png';
import Footerlogo from '../images/tns-logo.jpg';

function Applicantlogin() {
  
  
  return (
    <div className="col-md-8 m-auto">
      <div className="row mt-4">
        <div className="col-md-5 border mt-5 bg-white m-auto">
          <section className="p-4 right-section ">
              <div className="col-lg-10 m-auto ">
                  <a href="/"><img src={Headerlogo}
                          alt="H1BAPPLICANTS.COM" width="100%" /></a>
                  <div className="row mt-3">
                      <div className="col-md-12">
                          <h5>Applicant Login</h5>
                      </div>
                  </div>
                  <Login logintype="applicant"/>
              </div>
          </section>  
        </div>
      </div>
      <div className="col-md-5 mt-3 m-auto text-center">
        <p><strong>Owned &amp; managed by </strong></p>
      </div>
      <div className="col-md-5 m-auto text-center bg-white p-2 border"> 
        <a href="https://www.tnsservices.com/" target="_blank"><img src={Footerlogo} width="70%" alt="Trans National Software Services Inc." /></a> 
      </div>
    </div>
  )
}

export default Applicantlogin