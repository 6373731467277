import React,{useState,useEffect} from 'react'
import Modal from 'react-bootstrap/Modal';
import AuthUser from "../AuthUser";
import Dropdown from "react-bootstrap/Dropdown";
import Viewperformancefile from '../Applicants/Viewperformancefile';
import Doc from '../images/doc.png'
import Pdf from '../images/pdf.png'
function Performancereviewlist(props) {
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    let usertype = props.logintype;
    let dashboard=`/${usertype}`;
    const [performancelist, setPerformancelist] = useState([]);
    const [file, setFile] = useState(false);
    const [modalshow, setModalshow] = useState(false);
    const handleModalShow = (filename) =>{
        setModalshow(true);
        setFile(filename);
    } 
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={e => {
            e.preventDefault();
            onClick(e);
            }}
        >
            {children}
            <span className="threedots" />
        </a>
        ));
        const handleShowModal = (id) => {
           
        };
    function fetchItems(){        
        http.get(`${props.logintype}/performancereview/${userdetails.email}`)
        .then((response) => {
            setPerformancelist(response.data.performancereviews);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    useEffect(() => {
        fetchItems();
    },[]);
    const handleDownload = (quarter) =>{
        //console.log(filename);
        http.post(`${props.logintype}/performancedownload/${userdetails.email}`,{report:quarter}, {
            responseType: 'blob',
        })
        .then((response) => {
            //console.log(response);
            //let filename = filename;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url; //''
            link.setAttribute('download', quarter);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(function (error) {
            // handle error
            console.log('There was an error downloading the file!', error);
        });
    }
  return (
    <div className="container-fluid">
        <div className="col-md-9 m-auto p-0 pb-2 ">
            <div className='row'>
                <div className="col-md-6 text-left pull-left">
                    <h6 className="mb-2 font-weight-bold">Performance Review</h6>
                </div>
                <div className="col-md-6 text-right pull-right">
                    <a href={dashboard} className="mb-2 font-weight-bold">Back</a>           
                </div>
            </div>
        </div>
        <div className="col-md-9 bg-white m-auto shadow-sm p-4" style={{ borderTop: '5px solid #DF8E2E' }}>
            
            <table data-toggle="table" data-classes="table table-hover table-condensed"
                data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla"
                className="Tabla table table-hover table-condensed" data-page-length='10'>
                <thead>
                    <tr>
                        <th >Employee Name</th>
                        <th >Q1(Jan to Mar)</th>
                        <th >Q2(Apr to Jun)</th>
                        <th >Q3(Jul to Sep)</th>
                        <th >Q4(Oct to Dec)</th>
                        <th data-sortable="true">Year</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        performancelist.map(performance =>(
                            <tr>
                                <td>{performance.name} {performance.lastname}</td>
                                <td>
                                    <div className="row">
                                        <div className="col-9"><img src={performance.Q1.split('.').pop() == 'pdf' ? Pdf : Doc} />{performance.Q1}</div>                           
                                        <div className="col-3">
                                            <Dropdown>
                                                <Dropdown.Toggle as={CustomToggle} />
                                                <Dropdown.Menu size="sm" title="">
                                                    <Dropdown.Item key={performance.id} href="javascript:void(0);" onClick={()=>handleModalShow(performance.Q1)}>View</Dropdown.Item> 
                                                    <Dropdown.Item key={performance.id} href="javascript:void(0);" onClick={()=>handleDownload(performance.Q1)}>Download</Dropdown.Item>                                                    
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="row">
                                        <div className="col-9"><img src={performance.Q2.split('.').pop() == 'pdf' ? Pdf : Doc} />{performance.Q2}</div>                           
                                        <div className="col-3">
                                            <Dropdown>
                                                <Dropdown.Toggle as={CustomToggle} />
                                                <Dropdown.Menu size="sm" title="">
                                                    <Dropdown.Item key={performance.id} href="javascript:void(0);" onClick={()=>handleModalShow(performance.Q2)}>View</Dropdown.Item> 
                                                    <Dropdown.Item key={performance.id} href="javascript:void(0);" onClick={()=>handleDownload(performance.Q2)}>Download</Dropdown.Item>                                                     
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="row">
                                        <div className="col-9"><img src={performance.Q3.split('.').pop() == 'pdf' ? Pdf : Doc} />{performance.Q3}</div>                           
                                        <div className="col-3">
                                            <Dropdown>
                                                <Dropdown.Toggle as={CustomToggle} />
                                                <Dropdown.Menu size="sm" title="">
                                                    <Dropdown.Item key={performance.id} href="javascript:void(0);" onClick={()=>handleModalShow(performance.Q3)}>View</Dropdown.Item> 
                                                    <Dropdown.Item key={performance.id} href="javascript:void(0);" onClick={()=>handleDownload(performance.Q3)}>Download</Dropdown.Item>                                                      
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="row">
                                        <div className="col-9"><img src={performance.Q4.split('.').pop() == 'pdf' ? Pdf : Doc} />{performance.Q4}</div>                           
                                        <div className="col-3">
                                            <Dropdown>
                                                <Dropdown.Toggle as={CustomToggle} />
                                                <Dropdown.Menu size="sm" title="">
                                                    <Dropdown.Item key={performance.id} href="javascript:void(0);" onClick={()=>handleModalShow(performance.Q4)}>View</Dropdown.Item> 
                                                    <Dropdown.Item key={performance.id} href="javascript:void(0);" onClick={()=>handleDownload(performance.Q4)}>Download</Dropdown.Item>                                                      
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </td>
                                <td>{performance.year}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
        <Viewperformancefile show={modalshow} filename={file} foldertype="empselfperformance" close={() => setModalshow(false)} />
    </div>
  )
}

export default Performancereviewlist