import React, { useState, useEffect } from "react";
import AuthUser from "../AuthUser";

function EmployerDashboard(props) {
    let usertype = props.logintype;
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    const [ticketcount, setTicketcount] = useState(0);
    const [premium, setPremium] = useState(0);
    const [regular, setRegular] = useState(0);
    const [activecount, setActivecount] = useState(0);
    const [inactivecount, setInactivecount] = useState(0);
    const [applicantcount, setApplicantcount] = useState(0);
    const [msacount, setMsacount] = useState(0);
    const [sowcount, setSowcount] = useState(0);
    const [withdraw, setWithdrawcount] = useState(0);
    const [sampledocs, setSampledocs] = useState(0);
    const [lcacount, setLcacount] = useState(0);
    const [publiccount, setPubliccount] = useState(0);
    const [compcount, setCompcount] = useState(0);
    const [usciscount, setUsciscount] = useState(0);
    const [h1bcount, setH1bcount] = useState(0);
    const [inviteecount, setInvitecount] = useState(0);
    useEffect(() => {
        http.get(`${usertype}/dashboard/${userdetails.email}`)
          .then((response) => {
            //console.log(response.status);
            //console.log(response.data);
            setTicketcount(response.data.ticketscount);
            setPremium(response.data.premiumcount);
            setRegular(response.data.regularcount);
            setActivecount(response.data.activecount);
            setInactivecount(response.data.inactivecount);
            setApplicantcount(response.data.applicantscount);
            setMsacount(response.data.msacount);
            setSowcount(response.data.sowcount);
            setWithdrawcount(response.data.withdrawcount);
            setSampledocs(response.data.samplecount);
            setLcacount(response.data.lcacount);
            setPubliccount(response.data.publiccount);
            setCompcount(response.data.compcount);
            setUsciscount(response.data.usciscount);
            setH1bcount(response.data.h1bcount);
            setInvitecount(response.data.inviteecount);
          })
          .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
          });
      }, []);
  return (
    <>
        <div className="col-lg-12 m-auto">
            <h6 className="mb-2 font-weight-bold pl-2">Dashboard</h6>
        </div>
        <div className="col-lg-9 bg-white mb-3 p-4 m-auto border">
            <div className="row ">
                <div className="col-xl-12 col-md-6 mb-4 border-left">
                    <div className="row" style={{ fontWeight: '500' }}>
                        <div className="col-md-4 border-right">

                            <ul className="list-group ">
                                <a href="/employer/tickets"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Tickets</div> Create,View and manage tickets
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}>{ticketcount}</span>
                                </a>
                                <a href="/employer/premiumtickets"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Premium processing</div> View and manage premium processing cases
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}>{premium}</span>
                                </a>
                                <a href="/employer/regulartickets"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Regular Processing</div> View and manage Regular processing cases
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}>{regular}</span>
                                </a>
                                <a href="#"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Reports</div> View and download reports
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}></span>
                                </a>
                                <a href="/employer/publicDocuments"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Public access files</div> View and mange public access files
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}>{publiccount}</span>
                                </a>
                                <a href="/employer/publicCompliance"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Public access files compliance</div> View and mange public
                                        access files compliance
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}>{compcount}</span>
                                </a>
                                <a href="/employer/uscisDocuments"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">USCIS documentation</div> View and mange documents submitted to
                                        USCIS
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}>{usciscount}</span>
                                </a>
                                <a href="/employer/msaDocuments" 
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">MSA</div> View and mange master service agreements
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}>{msacount}</span>
                                </a>
                                <a href="/employer/sowDocuments"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">SOW</div> View and mange SOW
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}>{sowcount}</span>
                                </a>
                                <a href="/employer/sampleDocuments"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Sample Documents</div> View and mange sample documents
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}>{sampledocs}</span>
                                </a>
                                <a href="/employer/inprocesslca"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Inprocess LCA</div> View and mange Inprocess LCA
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}></span>
                                </a>
                            </ul>
                        </div>
                        <div className="col-md-4 border-right">

                            <ul className="list-group">
                                <a href="/employer/prelcafiling"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Pre LCA filing posting Notice</div> View and mange Pre LCA filing posting Notice
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}></span>
                                </a>
                                <a href="/employer/gcaudits"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">GC Audit Logs</div> View and mange GC Audit Logs
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}></span>
                                </a>
                                                            <a href="/employer/lca"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">LCA</div> Create,View and manage LCA
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}>{lcacount}</span>
                                </a>
                                <a href="/employer/applicants"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">IT Consultants</div> View and manage IT Consultants<br/>
                                        <span className="fw-bold">Active </span><span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}>{activecount}</span>&nbsp;
                                        <span className="fw-bold">In-Active </span><span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}>{inactivecount}</span>
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}>{applicantcount}</span>
                                </a>

                                <a href="/employer/h1bQuestionaire"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">H1B Questionaire</div> View and manage H1B Questionaire
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}>{h1bcount}</span>
                                </a>

                                <a href="/employer/gc"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">GC</div> View and manage GC
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}>{applicantcount}</span>
                                </a>

                                <a href="/employer/uscisprocesstimes"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">USCIS Case Processing Times</div> View USCIS Case Processing
                                        Times
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}></span>
                                </a>

                                <a href="/employer/sendinvite"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Invitee</div> View Invitee Applicants
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}>{inviteecount}</span>
                                </a>

                                <a href="/employer/withdrawDocuments"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Cancellations / Withdrawals</div>View and manage cancellations
                                        / withdrawals
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}>{withdraw}</span>
                                </a>
                                <a href="/employer/formnine"
                                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Form I9 info</div>View and manage Form I9 info
                                    </div> <span className="badge  rounded-0" style={{ backgroundColor: '#2F9DCC' }}>{applicantcount}</span>
                                </a>

                            </ul>
                        </div>
                        <div className="col-md-4 border-right">
                            <ul className="list-group">
                                <a href="/employer/newempdocuments" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0  list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">New Employee Documentation</div> View all New Employee Documents</div></a>
                                <a href="/employer/employees" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0  list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">Employees</div> View all Employees</div></a>
                                <a href="/employer/performancereview" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">Performance Review</div> View Performance Review</div></a>
                                <a href="/employer/timesheet" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">Timesheets</div> View and manage timesheets</div></a>
                                <a href="/employer/events" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold"> Events</div>View and manage all events</div></a>
                                <a href="/employer/payrollcalendar" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">Payroll Calendar</div> View Payroll Calendar</div></a>
                                <a href="/employer/paychexlogin" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">Paychex Login Info</div> Create account in Paychex to view and manage paystub,w2, address and other info.</div></a>
                                <a href="#" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">Documents</div> View all documents</div></a>
                                <a href="/employer/referralpolicy" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">Employee Referral Policy</div> View Employee Referral Policy</div></a>
                                <a href="/employer/requests" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">Requests</div> View all requests</div></a>
                                <a href="/employer/suggestions" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">Suggestions</div> View and submit suggestions</div></a>
                                <a href="/employer/feedbacks" className="list-group-item d-flex justify-content-between align-items-start border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">Feedback</div> View and submit your feedback</div></a>
                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </>
  )
}

export default EmployerDashboard