import React, { Component } from 'react'
import logger from '../utils/logger';
import AuthUser from '../AuthUser';
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an external service
    const { http, getUser } = AuthUser();
    logger.error('Error:', error);
    logger.error('Error Info:', errorInfo);
    // Optionally send the log to a server
    http.post(`/storeerrorlog`,{error:error,errorInfo:errorInfo}).then((response)=>{
        //console.log(response.data);
        
    }).catch(function (error) {
      // handle error
      //console.log(error.response.data.error);      
      //setsubmitted(false)
    })
    /*
    fetch('/log', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ error, errorInfo }),
    });
    */
  }
  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
