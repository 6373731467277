import React,{useState,useEffect} from 'react'
import AuthUser from '../AuthUser';
import Modal from 'react-bootstrap/Modal';
import Dropdown from "react-bootstrap/Dropdown";
function Pointofcontact(props) {
    let usertype = props.logintype;
    let apiurl = props.apiurl;
    const [applicants, setApplicants] = useState([])
    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const[message,setMessage]=useState();
    const[error,setError]=useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [values,setValues]=useState({firstname:'',lastname:'',email:'',mobileno:'',jobtitle:''});
    const [errors, setErrors] = useState({firstname:'',lastname:'',email:'',mobileno:'',jobtitle:''});
    const [selectedid, setSelectedid] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a href="#" ref={ref} onClick={e => {
            e.preventDefault();
            onClick(e);
            }}
        >
            {children}
            <span className="threedots" />
        </a>
    ));
    function fetchItems(){
        http.get(`${usertype}/${apiurl}/${userdetails.email}`)
        .then((response) => {
            console.log(response.data);
            setApplicants(response.data.pointofcontact);
            setFilteredData(response.data.pointofcontact);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    useEffect(() => {
        fetchItems();
    }, []);
    const handleFilterChange = (e) => {
        const value = e.target.value.toLowerCase();
        setFilter(value);

        const filtered = applicants.filter(item =>
            item.firstname.toLowerCase().includes(value) || item.lastname.toLowerCase().includes(value) || item.email.toLowerCase().includes(value) || item.mobileno.toLowerCase().includes(value)
            // Add more conditions based on your data structure
        );

        setFilteredData(filtered);
    };
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Validate email
        if (!values.firstname.trim()) {
            newErrors.firstname = 'First name is required';
            isValid = false;
        } else {
            newErrors.firstname = '';
        }
        if (!values.lastname.trim()) {
            newErrors.lastname = 'Last name is required';
            isValid = false;
        } else {
            newErrors.lastname = '';
        }
        if (!values.email.trim()) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else  if(!emailRegex.test(values.email)){
            newErrors.email = 'Email not valid';
            isValid = false;
        }else {
            newErrors.email = '';
        }
        if (!values.mobileno.trim()) {
            newErrors.mobileno = 'Mobile number is required';
            isValid = false;
        } else {
            newErrors.mobileno = '';
        }
        if (!values.jobtitle.trim()) {
            newErrors.jobtitle = 'Job title is required';
            isValid = false;
        } else {
            newErrors.lastname = '';
        }
        setErrors(newErrors);
        return isValid;
    };
    function handleChange(e){
        setValues({...values,[e.target.name]:e.target.value})
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        //validation(values); 
        let firstname = values.firstname;
        let lastname = values.lastname;
        let email = values.email;
        let mobileno = values.mobileno;
        let jobtitle = values.jobtitle;
        const newErrors = { ...errors };        
        if (validateForm()) {
          http.post(`admin/addpointofcontact`,{firstname:firstname,lastname:lastname,email:email,mobileno:mobileno,jobtitle:jobtitle}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              //console.log(response.data.message);
              setMessage(response.data.message);
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status===403){
              newErrors.title = error.response.data?.error?.title;
              newErrors.document = error.response.data?.error?.document;
              setErrors(newErrors);
            }
            if(error.response.status===401){
              setErrors(error.response.data?.error);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
    const handleDelete = () => {
        http.post(`${usertype}/deletepointofcontact`,{id:selectedid})
        .then((response) => {
            //console.log(response); 
            fetchItems();
            handleCloseModal();           
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    };

    const handleShowModal = (id) => {
        setSelectedid(id);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedid(null);
        setShowModal(false);
    };
  return (
    <div className="container-fluid">
        <div className="row mb-2 ">
            <div className="col-md-6">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item "><a href="/admin">Dashboard</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Point of contact</li>
                    </ol>
                </nav>
            </div>
            <div className="col-md-2 small  text-right">
                <div className="row">
                    <input type="text" name="email" value={filter}
                onChange={handleFilterChange}  className="form-control searchEmail" placeholder="Search by First name, last name, phone or email id..." />
                </div>
            </div>
            <div className="col-md-4 text-right">
                <a onClick={handleShow} href="#"  data-toggle="modal" data-target="#adddocument" className="text-primary pr-3"> <i className="bi bi-plus"></i> Add Point of contact</a>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 pb-5">
                <div className="card  mb-4">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table  data-toggle="table" className="table table-hover table-condensed" 
                                    data-striped="true"  data-sort-name="Quality"  data-sort-order="desc" id="Tabla" data-page-length='10'>
                                <thead>
                                    <tr>
                                        <th data-sortable="true">First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th className="text-center">Tel #</th>
                                        <th data-sortable="true" className="text-center">Job Ttile</th>
                                        <th data-sortable="true">Created Date / Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map(applicant =>(
                                        <tr key={applicant.id}>
                                            <td>
                                                <div className="row">
                                                    <div className="col-9" > {applicant.firstname}</div>
                                                    <div className="col-3">
                                                        
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle} />
                                                            <Dropdown.Menu size="sm" title="">
                                                                <Dropdown.Item href="javascript:void(0);" onClick={()=>handleShowModal(applicant.id)} >Delete</Dropdown.Item>
                                                                
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{applicant.lastname}</td>
                                            <td>{applicant.email}</td>
                                            <td className="text-center">+1 {applicant.mobileno}</td>
                                            <td className="text-center">{applicant.jobtitle}</td>
                                            <td >{applicant.created_at}</td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </table>                
                        </div>
                    </div>
                </div>            
            </div>
        </div>
        <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Add Point of contact</Modal.Title>
            </Modal.Header>
            <form method="POST" onSubmit={handleSubmit}>
            <Modal.Body className='modal-body'>                
                <div class="row">
                    <div class="input_fields_wrap">
                        <div class="mb-2 col-md-12">
                            <label class="form-label small" style={{ color: '#2D8BB4' }}><strong>First Name*</strong></label>
                            <input id="name" name="firstname" class="input form-control rounded-0 textOnly" type="text" placeholder=" " onChange={handleChange} />
                            <div className="invalid-feedback small">{errors.firstname}</div>
                        </div>
                        <div class="mb-2 col-md-12">
                            <label class="form-label small" style={{ color: '#2D8BB4' }}><strong>Last Name*</strong></label>
                            <input id="name" name="lastname" class="input form-control rounded-0 textOnly" type="text" placeholder=" " onChange={handleChange} />
                            <div className="invalid-feedback small">{errors.lastname}</div>
                        </div>
                        <div class="mb-2 col-md-12">
                            <label class="form-label small" style={{ color: '#2D8BB4' }}><strong>Email*</strong></label>
                            <input type="Email" class="form-control rounded-0 mt-2 mb-2 input" id="Email" name="email" placeholder=" " onChange={handleChange}/>
                            <div className="invalid-feedback small">{errors.email}</div>
                        </div>
                        <div class="mb-2 col-md-12">
                            <label class="form-label small" style={{ color: '#2D8BB4' }}><strong>Mobile Number*</strong></label>
                            <input type="phone" id="phone" name="mobileno" class="form-control rounded-0 numbersOnly" onChange={handleChange} placeholder="Mobile Number" />
                            <div className="invalid-feedback small">{errors.mobileno}</div>
                        </div>
                        <div class="mb-2 col-md-12">
                            <label class="form-label small" style={{ color: '#2D8BB4' }}><strong>Job Title*</strong></label>
                            <input type="text" id="jobtitle" name="jobtitle" class="form-control rounded-0 textOnly" onChange={handleChange} placeholder="Job Title" />
                            <div className="invalid-feedback small">{errors.jobtitle}</div>
                            <div className="alert-success small">{message}</div>
                        </div>
                    </div>                    
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div class="mb-2 col-md-12 mt-5 text-center">
                    <button type="reset" class="btn btn-secondary ml-2" data-dismiss="modal">Cancel</button>
                    <button type="submit" onClick={validateForm} class="btn btn-primary ml-2">Submit</button>
                </div>
            </Modal.Footer>
            </form>
        </Modal>
        <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>                
                <div className="input_fields_wrap">
                    <div className="mb-2 col-md-12">
                        <p style={{ color: '#2D8BB4' }}><strong>Are you sure you want to delete ?</strong></p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-2 col-md-12 text-center">
                    <button type="button" className="btn btn-secondary ml-2" onClick={handleCloseModal}>No</button> 
                    <button type="submit" onClick={handleDelete} className="btn btn-primary ml-2">Yes</button>
                </div>
            </Modal.Footer>
        </Modal>
    </div>

  )
}

export default Pointofcontact