import React,{useState,useEffect} from 'react'
import AuthUser from '../AuthUser';
import Modal from 'react-bootstrap/Modal';
function Tickettype(props) {
    let usertype = props.logintype;
    let pageview = props.pageview;
    let apiurl = props.apiurl;
    const [applicants, setApplicants] = useState([])
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [applicantcount, setApplicantcount] = useState(0)
    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [show, setShow] = useState(false);
    const [values,setValues]=useState({title:'',document:''});
    const [errors, setErrors] = useState({title: '',processing_type: '' });
    const { http, getUser } = AuthUser();
    const [showModal, setShowModal] = useState(false);
    const [selectedid, setSelectedid] = useState(null);
    const[message,setMessage]=useState();
    let userdetails = getUser();
    let dashboard=`/${usertype}`;
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    function fetchItems(){
        http.get(`${usertype}/${apiurl}/${userdetails.email}`)
        .then((response) => {
            console.log(response.data);
            setApplicants(response.data.tickettypelist);
            setFilteredData(response.data.tickettypelist);
        })
        .catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
        });
    }
    useEffect(() => {
       fetchItems(); 
    }, []);
    const handleFilterChange = (e) => {
        const value = e.target.value.toLowerCase();
        setFilter(value);
        const filtered = applicants.filter(item =>
            item.title.toLowerCase().includes(value) || item.processing_type.toLowerCase().includes(value)
            // Add more conditions based on your data structure
        );

        setFilteredData(filtered);
    };
    const handleCheckboxChange = (value) => {
        // Update the selected checkboxes array when a checkbox is checked or unchecked
        setSelectedCheckboxes((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected, value]
        );
      };
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Validate email
        if (!values.title.trim()) {
            newErrors.title = 'Ticket Title is required';
            isValid = false;
        } else {
            newErrors.title = '';
        }
        setErrors(newErrors);
        return isValid;
    };
      
    function handleChange(e){
        setValues({...values,[e.target.name]:e.target.value})
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        //validation(values); 
        let title = values.title;
        let processing_type = selectedCheckboxes;       
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`admin/addtickettypeapi`,{title:title,processing_type:processing_type}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              fetchItems();
              e.target.reset();
              setMessage('Ticket type added successfully!');
              console.log(response.data.message);
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
              newErrors.title = error.response.data?.error?.title;
              newErrors.document = error.response.data?.error?.document;
              setErrors(newErrors);
            }
            if(error.response.status==401){
              newErrors.message = error.response.data?.error;
              setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
    const handleDelete = () =>{
        http.post('/admin/deletetickettype',{ticketid:selectedid})
        .then((response) => {
            fetchItems();
            handleCloseModal(); 
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    } 
    const handleShowModal = (id) => {
        setSelectedid(id);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedid(null);
        setShowModal(false);
    };
  return (
    <div className="container-fluid">
        <div className="row mb-2 ">
        <div className="col-md-4">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                        <li className="breadcrumb-item "><a href="/admin">Dashboard</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Tickets List</li>
                </ol>
            </nav>
        </div>
        <div className="col-md-4 small  text-right">
            <div className="row">
                <input type="text" name="email" value={filter}
                onChange={handleFilterChange}  className="form-control searchTicket" placeholder="Search by ticket type..." />
            </div>
        </div>
        <div className="col-md-4 text-right">
                <a className="text-primary pr-3" onClick={handleShow} href="#" data-toggle="modal" data-target="#addticket"> <i className="bi bi-plus"></i> Add Ticket List </a> 
            </div>
        </div>
        <div className="row">
        <div className="col-md-9 bg-white shadow-sm p-0 mx-auto">
            <div className="card  mb-4">
            <div className="card-body">
                <div className="table-responsive">
                <table  data-toggle="table" className="Tabla table table-stripped table-hover table-condensed"  data-striped="true"  data-sort-name="Quality"  data-sort-order="desc" id="Tabla" data-page-length='10'>
                    <thead>
                    <tr>
                        <th data-sortable="true">Ticket Type</th>
                        <th>Processing Type</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        {filteredData.map(applicant =>(
                            <tr key={applicant.id}>
                                <td>{applicant.title}</td>
                                <td>{applicant.processing_type}</td>
                                <td><a href="#" onClick={()=>handleShowModal(applicant.id)} >Delete</a></td>
                            </tr>
                        ))

                        }
                    </tbody>
                </table>                
                </div>
            </div>
            </div>
        </div>
        </div>
        <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Add Ticket Name</Modal.Title>
            </Modal.Header>
            <form method="POST" onSubmit={handleSubmit}>
            <Modal.Body className='modal-body'>                
                    <div className="mb-3">
                        <label for="exampleFormControlInput1" className="form-label">Ticket Name</label>
                        <input type="text" className="form-control rounded-2" name="title" id="Technology" onChange={handleChange} placeholder="Enter Ticket Title" />
                        <div className="invalid-feedback small">{errors.title}</div>
                    </div>
                    <div className="mb-3">
                        <label for="exampleFormControlInput1" className="form-label">Processing Type</label><br/>
                        <input type="checkbox" name="processing_type[]" value="Regular" checked={selectedCheckboxes.includes('Regular')}
                                onChange={() => handleCheckboxChange('Regular')}    id="processing_type" />&nbsp;<label>Regular</label>&nbsp;
                        <input type="checkbox" name="processing_type[]" value="Premium" checked={selectedCheckboxes.includes('Premium')}
                                onChange={() => handleCheckboxChange('Premium')} id="processing_type" />&nbsp;<label>Premium</label>
                        <div className="invalid-feedback small">{errors.processing_type}</div>
                    </div>
                    <div className="alert-success small">{message}</div>
                    <span className="form-control1"></span>
            </Modal.Body>
            <Modal.Footer>
                <button type="submit" onClick={validateForm} class="btn btn-primary rounded-0">Submit</button>
            </Modal.Footer>
            </form>
        </Modal>
        <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>                
                <div className="input_fields_wrap">
                    <div className="mb-2 col-md-12">
                        <p style={{ color: '#2D8BB4' }}><strong>Are you sure you want to delete ?</strong></p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-2 col-md-12 text-center">
                    <button type="button" className="btn btn-secondary ml-2" onClick={handleCloseModal}>No</button> 
                    <button type="submit" onClick={handleDelete} className="btn btn-primary ml-2">Yes</button>
                </div>
            </Modal.Footer>
        </Modal>
    </div>
  )
}

export default Tickettype