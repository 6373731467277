import React, { useState,useEffect } from 'react'
import AuthUser from '../AuthUser'
function Usersettings() {
    const[empstatus,setEmpstatus]=useState();
    const[attorneystatus,setAttorneystatus]=useState();
    const[error,setError]=useState();
    const[message,setMessage]=useState();
    const { http, getUser } = AuthUser();
    function fetchItems(){
        http.get('admin/usersettings')
        .then((response) => {
            console.log(response.data);
            setEmpstatus(response.data.settings.employer_register);
            setAttorneystatus(response.data.settings.attorney_register);
        })
        .catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
        });
    }
    useEffect(() => {
       fetchItems(); 
    }, []);
    function handleempChange(e){
      setEmpstatus(e.target.value)
    }
    function handleatChange(e){
        setAttorneystatus(e.target.value)
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        //validation(values); 
        let employer_register = empstatus;
        let attorney_register = attorneystatus; 
          http.post(`admin/Updatesettings`,{employer_register:employer_register,attorney_register:attorney_register}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              fetchItems();
              e.target.reset();
              setMessage('User settings updated successfully!');
              console.log(response.data.message);
              
          }).catch(function (error) {
            
              setError('Error while updating');
          })
    }
  return (
    <div className="container-fluid"> 
    <div className="col-md-6 m-auto p-0 pb-2 ">
        <h6 className="mb-2 font-weight-bold">My Settings</h6>
    </div>
    <div className="col-md-6 bg-white m-auto shadow-sm p-4" style={{ borderTop:'5px', solid:'#DF8E2E'}}>
        <form method="POST" onSubmit={handleSubmit} >
        <div className="row">
            <div className="mb-2 col-md-6">
              <label  className="form-label small" style={{ color: '#2D8BB4'}}><strong>Employer Register</strong></label>
            </div>
            <div className="mb-2 col-md-6">
              <p className="font-weight-bold text-dark">
                <input className="form-check-input" type="radio" name="employer_register" onChange={handleempChange} id="inlineRadio1" value="Y" 
                    checked={ empstatus === 'Y' ? true : '' } />
                <label className="form-check-label" for="ques">Activate</label>
                </p><p className="font-weight-bold text-dark">
                <input className="form-check-input" type="radio" name="employer_register" onChange={handleempChange} id="inlineRadio1" value="N" 
                    checked={ empstatus === 'N' ? true : '' } />
                <label className="form-check-label" for="ques">De Activate</label>
              </p>
            </div>
            <div className="mb-2 col-md-6">
              <label  className="form-label small" style={{ color: '#2D8BB4' }}><strong>Attorney Register</strong></label>
            </div>
            <div className="mb-2 col-md-6">
              <p className="font-weight-bold text-dark">
                <input className="form-check-input" type="radio" name="attorney_register" onChange={handleatChange} id="inlineRadio1" value="Y" 
                    checked={ attorneystatus === 'Y' ? true : '' } />
                <label className="form-check-label" for="ques">Activate</label>
                </p><p className="font-weight-bold text-dark">
                <input className="form-check-input" type="radio" name="attorney_register" onChange={handleatChange} id="inlineRadio1" value="N" 
                    checked={ attorneystatus === 'N' ? true : '' } />
                <label className="form-check-label" for="ques">De Activate</label>
              </p>
            </div>
            <div className="col-12 mt-3 text-center">
                <button className="btn text-white" type="submit" style={{ backgroundColor: '#81A744' }}>Update</button>
            </div>
        </div>
        </form>
    </div>
</div>
  )
}

export default Usersettings