import React,{useState} from 'react'
import AuthUser from "../AuthUser";

function Feedbacks() {
    const [values,setValues]=useState({ overall:'',features:'',lookandfeel:'',satisfaction:'',support:'',comments:''});
    const [errors, setErrors] = useState({ overall:'',features:'',lookandfeel:'',satisfaction:'',support:'',comments:'',message:'' });
    const { http, getUser } = AuthUser();
    const [message,setMessage]= useState();
    let userdetails = getUser();
    let usertype = 'applicant';
    let dashboard=`/${usertype}`;
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Subject
        if (!values.overall.trim()) {
            newErrors.overall = 'Subject is required';
            isValid = false;
        } else {
            newErrors.overall = '';
        }
        // Message
        if (!values.features.trim()) {
            newErrors.features = 'Overall is required';
            isValid = false;
        } else {
            newErrors.features = '';
        }
        if (!values.lookandfeel.trim()) {
            newErrors.lookandfeel = 'Look and feel is required';
            isValid = false;
        } else {
            newErrors.lookandfeel = '';
        }
        if (!values.satisfaction.trim()) {
            newErrors.satisfaction = 'Satisfaction is required';
            isValid = false;
        } else {
            newErrors.satisfaction = '';
        }
        if (!values.support.trim()) {
            newErrors.support = 'Support is required';
            isValid = false;
        } else {
            newErrors.support = '';
        }
        // File Upload
        if (!values.comments.trim()) {
            newErrors.comments = 'Comment is required';
            isValid = false;
        } else {
            newErrors.comments = '';
        }

        setErrors(newErrors);
        setMessage('');
        return isValid;
    };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let overall = values.overall;
        let features = values.features;
        let lookandfeel = values.lookandfeel;
        let satisfaction = values.satisfaction;
        let support = values.support;
        let comments = values.comments;
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`applicant/feedback/${userdetails.email}`,{overall:overall,features:features,lookandfeel:lookandfeel,satisfaction:satisfaction,support:support,comments:comments,userid:userdetails.id}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              setValues({
                overall: '',
                features: '',
                lookandfeel: '',
                satisfaction: '',
                support: '',
                comments: '',
              });
              e.target.reset();
              setMessage('Feedback sent successfully!');
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.subject = error.response.data?.error?.subject;
                newErrors.referenceurl = error.response.data?.error?.referenceurl;
                newErrors.comment = error.response.data?.error?.comment;
                setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
  return (
    <div className="container-fluid">
        
        <div className="col-md-6 m-auto p-0 pb-2 ">
            <div className='row'>
                <div className="col-md-6 text-left pull-left">
                    <h6 className="mb-2 font-weight-bold">Feedback</h6>
                </div>
                <div className="col-md-6 text-right pull-right">
                    <a href={dashboard} className="mb-2 font-weight-bold">Back</a>           
                </div>
            </div>
        </div>
        <div className="col-md-6 bg-white m-auto shadow-sm p-4" style={{ borderTop: '5px solid #DF8E2E' }}>
            <h4 className="mb-3">Help us improve our product? Share your valuable feedback</h4>
            <form  method="POST" onSubmit={handleSubmit}>
                <div className="alert-success small"></div>
                <div className="form-group">
                    <h6 className="font-weight-bold">
                        <label htmlFor="exampleFormControlInput1">1. How would you rate your overall experience with portal ?</label>
                    </h6>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="overall" id="inlineRadio1" onChange={handleChange} value="Excellent" />
                        <label className="form-check-label" htmlFor="inlineRadio1">Excellent</label>
                    </div>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="overall" id="inlineRadio2" onChange={handleChange} value="Good" />
                        <label className="form-check-label" htmlFor="inlineRadio2">Good</label>
                    </div>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="overall" id="inlineRadio3" onChange={handleChange} value="Average" />
                        <label className="form-check-label" htmlFor="inlineRadio3">Average</label>
                    </div>        
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="overall" id="inlineRadio4" onChange={handleChange} value="Poor" />
                        <label className="form-check-label" htmlFor="inlineRadio3">Poor</label>
                    </div>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="overall" id="inlineRadio5" onChange={handleChange} value="Very Poor" />
                        <label className="form-check-label" htmlFor="inlineRadio3">Very Poor</label>
                    </div>
                    <br/>
                    <div className="invalid-feedback small">{errors.overall}</div>
                </div>
                <div className="form-group">
                    <h6 className="font-weight-bold">
                        <label htmlFor="exampleFormControlInput1">2. Overall experience with features ?</label>
                    </h6>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="features" id="inlineRadio1" onChange={handleChange} value="Excellent" />
                        <label className="form-check-label" htmlFor="inlineRadio1">Excellent</label>
                    </div>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="features" id="inlineRadio2" onChange={handleChange} value="Good" />
                        <label className="form-check-label" htmlFor="inlineRadio2">Good</label>
                    </div>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="features" id="inlineRadio3" onChange={handleChange} value="Average" />
                        <label className="form-check-label" htmlFor="inlineRadio3">Average</label>
                    </div>        
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="features" id="inlineRadio4" onChange={handleChange} value="Poor" />
                        <label className="form-check-label" htmlFor="inlineRadio3">Poor</label>
                    </div>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="features" id="inlineRadio5" onChange={handleChange} value="Very Poor" />
                        <label className="form-check-label" htmlFor="inlineRadio3">Very Poor</label>
                    </div>
                    <br/>
                    <div className="invalid-feedback small">{errors.features}</div>
                </div>
                <div className="form-group">
                    <h6 className="font-weight-bold">
                        <label htmlFor="exampleFormControlInput1">3. Look and feel of the portal ?</label>
                    </h6>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="lookandfeel" id="inlineRadio1" onChange={handleChange} value="Excellent" />
                        <label className="form-check-label" htmlFor="inlineRadio1">Excellent</label>
                    </div>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="lookandfeel" id="inlineRadio2" onChange={handleChange} value="Good" />
                        <label className="form-check-label" htmlFor="inlineRadio2">Good</label>
                    </div>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="lookandfeel" id="inlineRadio3"onChange={handleChange}  value="Average" />
                        <label className="form-check-label" htmlFor="inlineRadio3">Average</label>
                    </div>        
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="lookandfeel" id="inlineRadio4" onChange={handleChange} value="Poor" />
                        <label className="form-check-label" htmlFor="inlineRadio3">Poor</label>
                    </div>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="lookandfeel" id="inlineRadio5" onChange={handleChange}  value="Very Poor" />
                        <label className="form-check-label" htmlFor="inlineRadio3">Very Poor</label>
                    </div>
                    <br/>
                    <div className="invalid-feedback small">{errors.lookandfeel}</div>
                </div>
                <div className="form-group">
                    <h6 className="font-weight-bold">
                        <label htmlFor="exampleFormControlInput1">4. Overall Satisfaction ?</label>
                    </h6>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="satisfaction" id="inlineRadio1" onChange={handleChange} value="Excellent" />
                        <label className="form-check-label" htmlFor="inlineRadio1">Excellent</label>
                    </div>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="satisfaction" id="inlineRadio2" onChange={handleChange} value="Good" />
                        <label className="form-check-label" htmlFor="inlineRadio2">Good</label>
                    </div>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="satisfaction" id="inlineRadio3" onChange={handleChange} value="Average" />
                        <label className="form-check-label" htmlFor="inlineRadio3">Average</label>
                    </div>        
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="satisfaction" id="inlineRadio4" onChange={handleChange}  value="Poor" />
                        <label className="form-check-label" htmlFor="inlineRadio3">Poor</label>
                    </div>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="satisfaction" id="inlineRadio5" onChange={handleChange}  value="Very Poor" />
                        <label className="form-check-label" htmlFor="inlineRadio3">Very Poor</label>
                    </div>
                    <br/>
                    <div className="invalid-feedback small">{errors.satisfaction}</div>
                </div>
                <div className="form-group">
                    <h6 className="font-weight-bold">
                        <label htmlFor="exampleFormControlInput1">5. Our Support ?</label>
                    </h6>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="support" id="inlineRadio1" onChange={handleChange} value="Excellent" />
                        <label className="form-check-label" htmlFor="inlineRadio1">Excellent</label>
                    </div>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="support" id="inlineRadio2" onChange={handleChange} value="Good" />
                        <label className="form-check-label" htmlFor="inlineRadio2">Good</label>
                    </div>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="support" id="inlineRadio3" onChange={handleChange} value="Average" />
                        <label className="form-check-label" htmlFor="inlineRadio3">Average</label>
                    </div>        
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="support" id="inlineRadio4" onChange={handleChange} value="Poor" />
                        <label className="form-check-label" htmlFor="inlineRadio3">Poor</label>
                    </div>
                    <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="support" id="inlineRadio5" onChange={handleChange} value="Very Poor" />
                        <label className="form-check-label" htmlFor="inlineRadio3">Very Poor</label>
                    </div>
                    <br/>
                    <div className="invalid-feedback small">{errors.support}</div>
                </div>
                <div className="form-group">
                    <h6 className="font-weight-bold">
                        <label htmlFor="exampleFormControlInput1">6. Is there anything you would like to tell us?</label>
                    </h6>
                    <textarea className="form-control" id="exampleFormControlTextarea1" name="comments" onChange={handleChange} rows="5" placeholder="Comments..."></textarea>
                    <br/>
                    <div className="invalid-feedback small">{errors.comments}</div>
                    <div className="alert-success small">{message}</div>
                </div>
                <div className="d-grid gap-2 col-8 mx-auto mt-3">
                    <button className="btn text-white" type="submit" onClick={validateForm} style={{ backgroundColor: '#81A744' }}>Submit</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default Feedbacks