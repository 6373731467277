import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import AuthUser from "../AuthUser";
import Doc from '../images/doc.png'
import Pdf from '../images/pdf.png'

function Timesheets() {
    const [values,setValues]=useState({type:'',projecttitle:'',month:'',year:'',workhrs:'',document:''});
    const [errors, setErrors] = useState({type:'',projecttitle:'',month:'',year:'',workhrs:'',document:''});
    const [message,setMessage]= useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    let usertype = 'applicant';
    let dashboard=`/${usertype}`;
    const now = new Date();
    const current = now.getFullYear();
    const previous = current - 1;
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Subject
        if (!values.type.trim()) {
            newErrors.type = 'Project type is required';
            isValid = false;
        } else {
            newErrors.title = '';
        }
        // Message
        if (!values.projecttitle.trim()) {
            newErrors.projecttitle = 'Project title is required';
            isValid = false;
        } else {
            newErrors.projecttitle = '';
        }
        // File Upload
        if (!values.month.trim()) {
            newErrors.month = 'Month is required';
            isValid = false;
        } else {
            newErrors.month = '';
        }
        if (!values.year.trim()) {
            newErrors.year = 'Year is required';
            isValid = false;
        } else {
            newErrors.year = '';
        }
        if (!values.workhrs.trim()) {
            newErrors.workhrs = 'Work Hours required';
            isValid = false;
        } else {
            newErrors.workhrs = '';
        }
        if (!values.document.trim()) {
            newErrors.document = 'Document is required';
            isValid = false;
        } else {
            newErrors.document = '';
        }
        setErrors(newErrors);
        setMessage('');
        return isValid;
    };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let type = values.type;
        let projecttitle = values.projecttitle;
        let month = values.month;
        let year = values.year;
        let workhrs = values.workhrs;
        let document = e.target.document.files[0];
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`applicant/uploadtimesheet/${userdetails.email}`,{type:type,projecttitle:projecttitle,month:month,year:year,workhrs:workhrs,file_name:document,userid:userdetails.id}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              setValues({
                subject: '',
                document: '',
                message: '',
              });
              e.target.reset();
              setMessage('Request sent successfully!');
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.subject = error.response.data?.error?.subject;
                newErrors.document = error.response.data?.error?.document;
                newErrors.message = error.response.data?.error?.message;
                setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
  return (
    <div className="row p-3">
        <div className="row ">
            <div className="col-md-6  m-auto">
                <div className="row mb-3">
                    <div className="col-md-6">
                        <h5><strong>Timesheets</strong></h5>
                    </div>
                    <div className="col-md-6 text-right">
                        <a href={dashboard} className="mb-2 font-weight-bold">Back</a>    
                        <a href="javascript:void(0);" onClick={handleShow} className="btn btn-primary rounded-0" data-toggle="modal" data-target="#submit-timesheet">
                            <i className="bi bi-clock-history"></i> Submit Time Sheets
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-6 m-auto rounded bg-white border">
            <div className="row">
                <div className="col-md-6 p-3 border-bottom">
                    <a className="text-primary" href="/applicant/timesheetreport/01">Jan 2024</a><span className="pl-2"> </span>
                </div>
                                <div className="col-md-6 p-3 border-bottom">
                    <a className="text-primary" href="/applicant/timesheetreport/02">Feb 2024</a><span className="pl-2"> </span>
                </div>
                                <div className="col-md-6 p-3 border-bottom">
                    <a className="text-primary" href="/applicant/timesheetreport/03">Mar 2024</a><span className="pl-2"> </span>
                </div>
                                <div className="col-md-6 p-3 border-bottom">
                    <a className="text-primary" href="/applicant/timesheetreport/04">Apr 2024</a><span className="pl-2"> </span>
                </div>
                                <div className="col-md-6 p-3 border-bottom">
                    <a className="text-primary" href="/applicant/timesheetreport/05">May 2024</a><span className="pl-2"> </span>
                </div>
                                <div className="col-md-6 p-3 border-bottom">
                    <a className="text-primary" href="/applicant/timesheetreport/06">Jun 2024</a><span className="pl-2"> </span>
                </div>
                                <div className="col-md-6 p-3 border-bottom">
                    <a className="text-primary" href="/applicant/timesheetreport/07">Jul 2024</a><span className="pl-2"> </span>
                </div>
                                <div className="col-md-6 p-3 border-bottom">
                    <a className="disabled-link" href="javascript:void(0)">Aug 2024</a><span className="pl-2"> </span>
                </div>
                                <div className="col-md-6 p-3 border-bottom">
                    <a className="disabled-link" href="javascript:void(0)">Sep 2024</a><span className="pl-2"> </span>
                </div>
                                <div className="col-md-6 p-3 border-bottom">
                    <a className="disabled-link" href="javascript:void(0)">Oct 2024</a><span className="pl-2"> </span>
                </div>
                                <div className="col-md-6 p-3 border-bottom">
                    <a className="disabled-link" href="javascript:void(0)">Nov 2024</a><span className="pl-2"> </span>
                </div>
                                <div className="col-md-6 p-3 border-bottom">
                    <a className="disabled-link" href="javascript:void(0)">Dec 2024</a><span className="pl-2"> </span>
                </div>
                                <div className="col-md-6 p-3 border-bottom">
                    <a href="/applicant/timesheetreport/01,02,03" className="text-primary">Q1 Jan - Mar</a><span className="pl-2">  </span>
                </div>
                <div className="col-md-6 p-3 border-bottom">
                    <a href="/applicant/timesheetreport/04,05,06" className="text-primary">Q2 Apr - Jun</a><span className="pl-2">   </span>
                </div>
                <div className="col-md-6 p-3 border-bottom">
                    <a className="disabled-link" href="javascript:void(0)">Q3 Jul - Sep</a><span className="pl-2">   </span>
                </div>
                <div className="col-md-6 p-3 border-bottom">
                    <a className="disabled-link" href="javascript:void(0)">Q4 Oct - Dec</a><span className="pl-2">   </span>
                </div>
                <div className="col-md-12 p-3 border-bottom">
                    <a href={`/applicant/timesheetreport/${current}`} className="text-primary">Year to Date</a><span className="pl-2"> </span>
                </div>
                <div className="mt-2">Historical Data</div>
                <div className="col-md-3 p-3 border-bottom"> <a href={`/applicant/timesheetreport/${current}`} className="text-primary">2024</a><br/>
                    <span> </span>
                </div>
                <div className="col-md-3 p-3 border-bottom"> <a href={`/applicant/timesheetreport/${previous}`} className="text-primary">2023</a><br/>
                    <span> </span>
                </div>
            </div>
        </div>
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter"><span className="pl-2"> <i className="bi bi-clock-history"></i>Submit Time Entries </span></Modal.Title>
            </Modal.Header>
            <form method="POST" onSubmit={handleSubmit}>
            <Modal.Body className='modal-body'>                
            <div className="col-md-12 p-2 border customer_records" style={{ backgroundColor: '#F6F6F6' }}>
                        <div className="mb-3">
                            <select className="form-select rounded-0 mt-2" name="type" onChange={handleChange} aria-label="select example">
                                <option value="">Select Project Type</option>
                                <option value="Internal">Internal</option>
                                <option value="External">External</option>
                            </select>
                            <div className="invalid-feedback small">{errors.type}</div>
                            <input type="text" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="projecttitle" onChange={handleChange} placeholder="Enter Project / Client name *" />
                            <div className="invalid-feedback small">{errors.projecttitle}</div>
                            <div className="row mt-2">
                                <div className="col-md-6">
                                    <select className="form-select rounded-0" name="month" onChange={handleChange} required="" aria-label="select example">
                                        <option value="">Select Month *</option>
                                        <option value="01">January</option>
                                        <option value="02">February</option>
                                        <option value="03">March</option>
                                        <option value="04">April</option>
                                        <option value="05">May</option>
                                        <option value="06">June</option>
                                        <option value="07">July</option>
                                        <option value="08">August</option>
                                        <option value="09">September</option>
                                        <option value="10">October</option>
                                        <option value="11">November</option>
                                        <option value="12">December</option>
                                    </select>
                                    <div className="invalid-feedback small">{errors.month}</div>
                                </div>
                                <div className="col-md-6">
                                    <select className="form-select rounded-0" name="year" required="" onChange={handleChange}  aria-label="select example">
                                        <option value="">Select Year *</option>
                                        <option value="2024">2024</option>
                                        <option value="2023">2023</option>
                                    </select>
                                    <div className="invalid-feedback small">{errors.year}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mt-2">
                                    <input type="text" name="workhrs" className="form-control  rounded-0 workHrs" maxlength="6" placeholder="Total Working Hours *" onChange={handleChange} />
                                    <div className="invalid-feedback small">{errors.workhrs}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt-4">
                                    <div className="form-group">
                                        <input className="form-control file" type="file" name="document"  accept=".docx,application/pdf" id="formFile" onChange={handleChange} />
                                        <div className="invalid-feedback small">{errors.document}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="alert-success small">{message}</div>
                        </div>
                    </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-2 col-md-12 mt-5 text-center">
                    <button type="reset" className="btn btn-secondary ml-2" data-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary ml-2" onClick={validateForm}>Submit</button>
                </div>
            </Modal.Footer>
            </form>
        </Modal>
    </div>
  )
}

export default Timesheets