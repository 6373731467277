import React, { useState, useEffect } from 'react'
import AuthUser from "../AuthUser";
import Doc from '../images/doc.png'
import Pdf from '../images/pdf.png'
import Viewdocument from '../Common/Viewdocument';
function H1bdocuments(props) {
    let usertype = props.logintype;
    const[loader,setLoader]=useState(false);
    const [sampledocs, setSampledocs] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [file, setFile] = useState(false);
    const [modalshow, setModalshow] = useState(false);
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    const handleModalShow = (filename) =>{
        setModalshow(true);
        setFile(filename);
    } 
    const fetchItems = async () => {
        
        http.get(`applicant/h1bdocsapi/${userdetails.email}`)
        .then((response) => {
            console.log(response.data);
            setSampledocs(response.data.h1bdocs);
            setFilteredData(response.data.h1bdocs);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    };
  useEffect(() => {
    fetchItems();
    
  }, []);
  const handleFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    setFilter(value);

    const filtered = sampledocs.filter(item =>
        item.title.toLowerCase().includes(value)
        // Add more conditions based on your data structure
    );

    setFilteredData(filtered);
};
const handleCheckboxChange = (value) => {
    // Update the selected checkboxes array when a checkbox is checked or unchecked
    setSelectedCheckboxes((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((item) => item !== value)
        : [...prevSelected, value]
    );
};
const handleAllCheckboxChange = () => {
    setAllChecked(!allChecked);
    setSelectedCheckboxes(filteredData.map(li => li.documents));
    if (allChecked) {
        setSelectedCheckboxes([]);
    }
};
//Download
const handleDownload = () =>{
    setLoader(true)
    http.post(`${usertype}/downloadFiles`,{files:selectedCheckboxes}, {
        responseType: 'blob',
    })
    .then((response) => {
        //console.log(response);
        let filename = (selectedCheckboxes.length === 1) ? selectedCheckboxes : 'download.zip';
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url; //''
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false)
    })
    .catch(function (error) {
        // handle error
        console.log(error.response.data.error);
    });
}
  return (
    <div className="container-fluid">
        <div className="row mb-2 ">
            <div className="col-md-6">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb p-0">
                        <li className="breadcrumb-item "><a href="/applicant">Dashboard</a></li>
                        <li className="breadcrumb-item active" aria-current="page">H1B Questionaire</li>
                    </ol>
                </nav>
            </div>
            <div className="col-md-4 small  text-right">
                <div className="row">
                    <input type="text" name="email" className="form-control searchEmail" placeholder="Search by title" value={filter}
                onChange={handleFilterChange} />
                </div>

            </div>

        </div>
            <div className="row">
                <div className="col-md-6">&nbsp;</div>
                <div className="col-md-6 text-right">
                    <input className="form-check-input" type="hidden" name="allmerge[]" />
                    <input type="hidden" name="user_id" value="1" />
                    <input type="hidden" className="form-control title" list="datalistOptions" id="exampleDataList" name="document_type" value="Sample" />
                    <button type="submit" className="btn btn-primary ml-2 mb-2" id="frm-download" name="merge" onClick={handleDownload} value="download">Download </button>
                </div>

            </div>
            <div className="row">
                <div className="col-md-12 pb-5">
                    <div className="card  mb-4">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table data-toggle="table" data-classes="table table-hover table-condensed"
                                    data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla"
                                    className="Tabla table table-hover table-condensed" data-page-length='10'>
                                    <thead>
                                        <tr>
                                            <th className="text-center" >
                                            <input name="select_all" id="example-select-all" type="checkbox" checked={allChecked} onChange={handleAllCheckboxChange}/>
                                            </th>
                                            <th data-sortable="true">Document Title</th>
                                            <th data-sortable="true"><i className="bi-paperclip"></i></th>
                                            <th data-sortable="true">Document Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.map(sampleDoc => (
                                            <tr key={sampleDoc.id}>
                                                <td className="text-center"><input type="checkbox" name="allmerge[]" checked={selectedCheckboxes.includes(sampleDoc.documents)}  onChange={() => handleCheckboxChange(sampleDoc.documents)} value={sampleDoc.documents} /></td>
                                                <td>{sampleDoc.title}</td>
                                                <td><a href="javascript:void(0);" onClick={() => handleModalShow(sampleDoc.documents)} ><img src={sampleDoc.documents.split('.').pop() == 'pdf' ? Pdf : Doc} />{sampleDoc.title}</a></td>
                                                <td>{sampleDoc.created_at}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <Viewdocument show={modalshow} filename={file} foldertype="admindocs" close={() => setModalshow(false)} />
    </div>
  )
}

export default H1bdocuments