import React, { useState, useEffect } from "react";
import AuthUser from "../AuthUser";
import Accordion from 'react-bootstrap/Accordion';

function ApplicantDashboard(props) {
  let usertype = props.logintype;
  const { http, getUser } = AuthUser();
  let userdetails = getUser();
  const [tickets, setTickets] = useState(0);
  const [sampledocs, setSampledocs] = useState(0);
  const [mydocs, setMydocs] = useState(0);
  const [gcdocs, setGcdocs] = useState(0);
  const [gcstatus, setGcstatus] = useState(false);
  const [pointofcontact, setPointofcontact] = useState({
    name: "",
    email: "",
    mobile: "",
    jobtitle: "",
  });
  useEffect(() => {
    http.get(`${usertype}/dashboard/${userdetails.email}`)
      .then((response) => {
        //console.log(response.status);
        console.log(response.data);
        setTickets(response.data.totaltickets);
        setSampledocs(response.data.samplecount);
        setMydocs(response.data.docscount);
        setGcdocs(response.data.gcdocscount);
        setGcstatus(response.data.gcstatus);
        setPointofcontact(response.data.pointofcontact);
      })
      .catch(function (error) {
        // handle error
        console.log(error.response.data.error);
      });
  }, []);
  return (
    <>
      <div className="col-lg-6 m-auto">
        <h6 className="mb-2 font-weight-bold pl-2">Dashboard</h6>
      </div>
      <div className="col-lg-6 bg-white mb-3 p-4 m-auto border ">
        <div className="row ">
          <div className="col-xl-12 col-md-6 mb-4 ">
            <div className="row" style={{ fontWeight: "500" }}>
              <div className="col-md-6 border-right">
                <ul className="list-group ">
                  <a
                    href="/applicant/tickets"
                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0  list-group-item-action"
                  >
                    <div className="ms-2 me-auto">
                      
                      <div className="fw-bold">My Tickets</div> View and manage
                      my tickets
                    </div>
                    <span
                      className="badge  rounded-0"
                      style={{ backgroundColor: "#2F9DCC" }}
                    >
                      {tickets}
                    </span>
                  </a>
                  <a
                    href="/applicant/sampleDocuments"
                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action"
                  >
                    <div className="ms-2 me-auto">
                      
                      <div className="fw-bold">Sample documents</div> View and
                      download sample documents
                    </div>
                    <span
                      className="badge  rounded-0"
                      style={{ backgroundColor: "#2F9DCC" }}
                    >
                      {sampledocs}
                    </span>
                  </a>
                  <a
                    href="/applicant/mydocs"
                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action"
                  >
                    <div className="ms-2 me-auto">
                      
                      <div className="fw-bold">My documents</div> View and
                      download my documents
                    </div>
                    <span
                      className="badge  rounded-0"
                      style={{ backgroundColor: "#2F9DCC" }}
                    >
                      {mydocs}
                    </span>
                  </a>
                  <a
                    href="/applicant/h1bQuestionaire"
                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action"
                  >
                    <div className="ms-2 me-auto">
                      
                      <div className="fw-bold">H1B Questionnaire</div> View and
                      download H1B Questionnaire
                    </div>
                  </a>
                  <a
                    href="#"
                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action"
                  >
                    <div className="ms-2 me-auto">
                      
                      <div className="fw-bold">
                        USCIS Case Processing Times
                      </div>
                      View USCIS Case Processing Times
                    </div>
                  </a>
                  {gcstatus === 'true' &&
                  <a href="/applicant/gcdocs" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action"
                  >
                    <div className="ms-2 me-auto">
                      
                      <div className="fw-bold">GC documents</div> View and
                      download GC documents
                    </div>
                    
                  </a>
                  }
                  <a
                    href="#"
                    className="list-group-item d-flex justify-content-between align-items-start border-left-0 border-right-0 border-top-0  list-group-item-action"
                  >
                    <div className="ms-2 me-auto">
                      
                      <div className="fw-bold">Point of contact</div>
                      <p>
                        
                        <strong>Name:</strong> {pointofcontact.firstname}&nbsp;
                        {pointofcontact.lastname}
                        <br />
                        <strong>Email:</strong> {pointofcontact.email} <br />
                        <strong>Mobile No:</strong> +1 {pointofcontact.mobileno}
                        <br />
                        <strong> Job Title:</strong> {pointofcontact.jobtitle}
                        <br />
                      </p>
                    </div>
                  </a>
                  <Accordion defaultActiveKey="">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>visa interview required documentation</Accordion.Header>
                      <Accordion.Body>
                          i797
                          <br />
                          LCA
                          <br />
                          vendor letter
                          <br />
                          client letter
                          <br />
                          MSA / SOW if in case of client project
                          <br />
                          letter from Employer to the consulate officer
                          <br />
                          recent three paystubs
                          <br />
                          education and experience letters
                          <br />
                          passport
                          <br />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="list-group">
                <a href="/applicant/newempdocuments"
                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0  list-group-item-action"
                  >
                    <div className="ms-2 me-auto">
                      
                      <div className="fw-bold">New Employee Documentation</div> View all New Employee Documents
                    </div>
                  </a>
                  <a
                    href="/applicant/myreportingmanager"
                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action"
                  >
                    <div className="ms-2 me-auto">                      
                      <div className="fw-bold">My Reporting Manager</div> View Reporting manager Info
                    </div>
                  </a>
                  <a
                    href="/applicant/performancereview"
                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action"
                  >
                    <div className="ms-2 me-auto">
                      
                      <div className="fw-bold">Performance Review</div> View Performance Review
                    </div>
                  </a>
                  <a
                    href="/applicant/timesheet"
                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action"
                  >
                    <div className="ms-2 me-auto">
                      
                      <div className="fw-bold">Timesheets</div> View and
                      manage timesheets
                    </div>
                  </a>
                  <a
                    href="/applicant/myrequests"
                    className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action"
                  >
                    <div className="ms-2 me-auto">
                      
                      <div className="fw-bold">
                        My Request
                      </div>
                      View and manage all requests
                    </div>
                  </a>
                  <a href="/applicant/payrollcalendar" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action"
                  >
                    <div className="ms-2 me-auto">                      
                      <div className="fw-bold">Payroll Calendar</div> View Payroll Calendar
                    </div>                    
                  </a>
                  <a href="/applicant/paychexlogin" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action"
                  >
                    <div className="ms-2 me-auto">
                      
                      <div className="fw-bold">Paychex Login Info</div> Create account in Paychex to view and manage paystub,w2, address and other info.
                    </div>
                    
                  </a>
                  <a href="/applicant/insurancevendorinfo" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action"
                  >
                    <div className="ms-2 me-auto">
                      
                      <div className="fw-bold">Insurance Vendor Info</div> View Insurance Vendor Info
                    </div>
                    
                  </a>
                  <a href="/applicant/referralpolicy" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action"
                  >
                    <div className="ms-2 me-auto">
                      
                      <div className="fw-bold">Employee Referral Policy</div> View Employee Referral Policy
                    </div>
                    
                  </a>
                  <a href="/applicant/myreferrals" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action"
                  >
                    <div className="ms-2 me-auto">
                      
                      <div className="fw-bold">My Referrals</div> View My Referrals
                    </div>
                    
                  </a>
                  <a href="/applicant/suggestion" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action"
                  >
                    <div className="ms-2 me-auto">
                      
                      <div className="fw-bold">Suggestions</div> View and submit suggestions
                    </div>
                    
                  </a>
                  <a href="/applicant/feedback" className="list-group-item d-flex justify-content-between align-items-start border-left-0 border-right-0 border-top-0 list-group-item-action"
                  >
                    <div className="ms-2 me-auto">
                      
                      <div className="fw-bold">Feedback</div> View and submit your feedback
                    </div>
                    
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplicantDashboard;
