import React from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import Home from '../Home';
import Applicantlogin from '../Components/Applicantlogin';
import Applicantregister from '../Components/Applicantregister';
import Employerlogin from '../Components/Employerlogin';
import Attorneylogin from '../Components/Attorneylogin';
import Adminlogin from '../Components/Adminlogin';
import Forgot from '../Common/Forgot';
import Resetpassword from '../Common/Resetpassword';
import Searchpfa from '../Searchpfa';
function Homenav() {
  
  
  return (
    <>        
      <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/searchpfadata/:id" element={<Searchpfa />} />
          <Route path="/applicantlogin" element={<Applicantlogin />} />
          <Route path="/applicantregister" element={<Applicantregister logintype="applicant" pageview="Applicant Register" apiurl="/applicant/register"/>} />
          <Route path="/applicantforgot" element={<Forgot />} />
          <Route path="/applicantresetpassword/:token/:email" element={<Resetpassword logintype="applicant" pageview="Reset Password" apiurl="passwordUpdate"/>} />
          <Route path="/applicantcreatepassword/:email" element={<Resetpassword logintype="applicant" pageview="Create Password" apiurl="updatePassword"/>} />
          <Route path="/employerlogin" element={<Employerlogin />} />
          <Route path="/employerregister" element={<Applicantregister logintype="employer" pageview="Employer Register" apiurl="/employer/register" />} />
          <Route path="/employerforgot" element={<Forgot />} />
          <Route path="/employerresetpassword/:token/:email" element={<Resetpassword logintype="employer" pageview="Reset Password" apiurl="passwordUpdate"/>} />
          <Route path="/employercreatepassword/:email" element={<Resetpassword logintype="employer" pageview="Create Password" apiurl="updatePassword"/>} />
          <Route path="/attorneylogin" element={<Attorneylogin />} /> 
          <Route path="/attorneyregister" element={<Applicantregister logintype="attorney" pageview="Attorney Register" apiurl="/attorney/register" />} />         
          <Route path="/attorneyforgot" element={<Forgot />} />     
          <Route path="/attorneyresetpassword/:token/:email" element={<Resetpassword logintype="attorney" pageview="Reset Password" apiurl="passwordUpdate"/>} /> 
          <Route path="/attorneycreatepassword/:email" element={<Resetpassword logintype="attorney" pageview="Create Password" apiurl="updatePassword"/>} />    
          <Route path="/adminlogin" element={<Adminlogin />} />
          <Route path="/adminforgot" element={<Forgot />} />
          <Route path="/adminresetpassword/:token/:email" element={<Resetpassword logintype="admin" pageview="Reset Password" apiurl="passwordUpdate"/>} />  
          <Route path="/admincreatepassword/:email" element={<Resetpassword logintype="admin" pageview="Create Password" apiurl="updatePassword"/>} />
          <Route path="*" element={<Home />} />    
       </Routes>
    </>
  )
}

export default Homenav