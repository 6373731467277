import React from 'react';
import { Routes, Route, Link, Router, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import AuthUser from '../AuthUser';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Headerlogo from '../images/H1B_Applicants_logo.png';
import AttorneyDashboard from '../Attorney/AttorneyDashboard';
import Changepassword from '../Common/Changepassword';
import SampleDocuments from '../Common/Sampledocuments';
import Lcalist from '../Employer/Lcalist';
import Myprofile from '../Common/Myprofile';
import Ticketslist from '../Common/Ticketslist';
import Editprofile from '../Common/Editprofile';
import Mydocuments from '../Applicants/Mydocuments';
import Resetpassword from '../Common/Resetpassword';

function AttorneyNav() {
    const {token,logout,getUser} = AuthUser();
    let userdetails = getUser();
    let loguser = userdetails.name+" "+userdetails.lastname;
    const logoutUser = () => {
        if(token !== undefined){
            logout();
        }
    }
    const isAuthenticated = (userdetails !== '') ? true : false;
  return (
    <>
        <Navbar expand="md" className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <Navbar.Brand href="/attorney"><img src={Headerlogo} width="80%" alt="H1B Applicants" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
            <NavDropdown className="navbar-nav ml-auto" title={loguser} id="navbarScrollingDropdown">
                <NavDropdown.Item href="/attorney/myprofile"><i className="bi-person-fill"></i>My Profile</NavDropdown.Item>
                <NavDropdown.Item href="/attorney/editprofile"><i className="bi-person-fill"></i>Edit Profile</NavDropdown.Item>
                <NavDropdown.Item href="/attorney/changepassword"><i className="bi-key-fill"></i>Change Password</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logoutUser}>Logout</NavDropdown.Item>
            </NavDropdown>
            </Navbar.Collapse>
        </Navbar>
        <Routes>
            <Route path="/attorney" element={isAuthenticated ? <AttorneyDashboard /> : <Navigate to="/" replace /> } />
            <Route path="/attorney/changepassword" element={isAuthenticated ? <Changepassword logintype="attorney"/> : <Navigate to="/" replace /> } />
            <Route path="/attorney/myprofile" element={isAuthenticated ? <Myprofile logintype="attorney" /> : <Navigate to="/" replace /> } />
            <Route path="/attorney/editprofile" element={isAuthenticated ? <Editprofile logintype="attorney" /> : <Navigate to="/" replace /> } />
            <Route path="/attorney/sampledocuments" element={isAuthenticated ? <SampleDocuments logintype="attorney" pageview="Sample Documents" doctype="Sample" apiurl="sampledocsapi" /> : <Navigate to="/" replace /> } />
            <Route path="/attorney/publicDocuments" element={isAuthenticated ? <SampleDocuments logintype="attorney" pageview="Public Access Files" doctype="PUBLIC" apiurl="publicdocsapi" /> : <Navigate to="/" replace /> } />
            <Route path="/attorney/uscisDocuments" element={isAuthenticated ? <SampleDocuments logintype="attorney" pageview="USCIS Documents" doctype="USCIS" apiurl="uscisdocsapi"/> : <Navigate to="/" replace /> } />
            <Route path="/attorney/h1bQuestionaire" element={isAuthenticated ? <SampleDocuments logintype="attorney" pageview="H1b Questionaire" doctype="H1BQ" apiurl="h1bdocsapi" /> : <Navigate to="/" replace /> }/>
            <Route path="/attorney/lca" element={isAuthenticated ? <Lcalist logintype="attorney" apiurl="lcalist"/> : <Navigate to="/" replace /> } />
            <Route path="/attorney/tickets" element={isAuthenticated ? <Ticketslist logintype="attorney" pageview="Tickets" apiurl="ticketslist"/> : <Navigate to="/" replace /> } />
            <Route path="/attorney/claimedtickets" element={isAuthenticated ? <Ticketslist logintype="attorney" pageview="My Tickets" apiurl="mytickets"/> : <Navigate to="/" replace /> } />
            <Route path="/attorney/opentickets" element={isAuthenticated ? <Ticketslist logintype="attorney" pageview="Open Tickets" apiurl="opentickets"/> : <Navigate to="/" replace /> } />
            <Route path="/attorney/closedtickets" element={isAuthenticated ? <Ticketslist logintype="attorney" pageview="Closed Tickets" apiurl="closedtickets"/> : <Navigate to="/" replace /> } />
            <Route path="/attorney/premiumtickets" element={isAuthenticated ? <Ticketslist logintype="attorney" pageview="Premium Tickets" apiurl="premiumtickets"/> : <Navigate to="/" replace /> } />
            <Route path="/attorney/Regulartickets" element={isAuthenticated ? <Ticketslist logintype="attorney" pageview="Regular Tickets" apiurl="regulartickets"/> : <Navigate to="/" replace /> } />
            <Route path="/attorney/mydocs/:id/:doctype" element={isAuthenticated ? <Mydocuments logintype="attorney" apiurl="documentview" pageview=""/> : <Navigate to="/" replace /> } />
            <Route path="/attorney/resetpassword/:token/:email" element={isAuthenticated ? <Resetpassword logintype="employer" pageview="Reset Password" apiurl="passwordUpdate" /> : <Navigate to="/" replace /> } />
            <Route path="/attorney/createpassword/:email" element={isAuthenticated ? <Resetpassword logintype="employer" pageview="Create Password" apiurl="updatePassword" /> : <Navigate to="/" replace /> } />
        </Routes>           
    </>
  )
}

export default AttorneyNav