import React from 'react'
import AuthUser from '../AuthUser';

function Myprofile(props) {
    let usertype = props.logintype;
    const {getUser} = AuthUser();
    let userdetails = getUser()
    //console.log(userdetails);
  return (
    <div className="container-fluid">
        <div className="col-md-6 m-auto p-0 pb-2 ">
          <h6 className="mb-2 font-weight-bold">My Profile</h6>
        </div>
        <div className="col-md-6 bg-white m-auto shadow-sm p-4" style={{ borderTop: '5px solid #DF8E2E' }}>
          <div className="row">
            <div className="mb-2 col-md-6">
              <label  className="form-label small" style={{ color: '#2D8BB4' }}><strong>First name</strong></label>
              <p className="font-weight-bold text-dark">{ userdetails.name } </p>
            </div>
            <div className="mb-2 col-md-6">
              <label  className="form-label small" style={{ color: '#2D8BB4' }}><strong>Last name</strong></label>
              <p className="font-weight-bold text-dark">{ userdetails.lastname }</p>
            </div>
            <div className="mb-2 col-md-6">
              <label  className="form-label small" style={{ color: '#2D8BB4' }}><strong>Phone number</strong></label>
              <p className="font-weight-bold text-dark">{ userdetails.phone }</p>
            </div>
            <div className="mb-2 col-md-6">
              <label  className="form-label small" style={{ color: '#2D8BB4' }}><strong>Email</strong></label>
              <p className="font-weight-bold text-dark">{ userdetails.email }</p>
            </div>
            <div className="mb-2 col-md-6">
              <label  className="form-label small" style={{ color: '#2D8BB4' }}><strong>Country</strong></label>
              <p className="font-weight-bold text-dark">{ userdetails.country }</p>
            </div>
            <div className="mb-2 col-md-6">
              <label  className="form-label small" style={{ color: '#2D8BB4' }}><strong>State</strong></label>
              <p className="font-weight-bold text-dark">{ userdetails.state }</p>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Myprofile