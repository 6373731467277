import React,{useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import AuthUser from "../AuthUser";
import Doc from '../images/doc.png'
import Pdf from '../images/pdf.png'
import Viewdocument from '../Common/Viewdocument';
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from 'react-bootstrap/Spinner';
function Mydocuments(props) {
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
        e.preventDefault();
        onClick(e);
        }}
    >
        {children}
        <span className="threedots" />
    </a>
    ));
    const [file, setFile] = useState(false);
    const[loader,setLoader]=useState(false);
    const [show, setShow] = useState(false);
    const [modalshow, setModalshow] = useState(false);
    const [mydocs, setMydocs] = useState([])
    const [values,setValues]=useState({title:'',document:''});
    const [errors, setErrors] = useState({title: '',document: '',message:'' });
    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [message,setMessage]= useState();
    const [username,setUsername]= useState({});
    const [showModal, setShowModal] = useState(false);
    const [selectedid, setSelectedid] = useState(null);
    const handleClose = () => { setShow(false);  setMessage(''); fetchItems(); } 
    const handleShow = () => setShow(true);
    const {id,doctype} = useParams();
    const handleModalShow = (filename) =>{
        setModalshow(true);
        setFile(filename);
    } 
    console.log(id);
    const { http, getUser } = AuthUser();
    let usertype=props.logintype;
    let userdetails = getUser();
    let dashboard=`/${usertype}`;
    let purl=doctype;
    let docstype=(usertype === 'applicant' ) ? props.dtype : (doctype === 'GCDOCS' ? 'GCDOCS' : doctype === 'DOCS' ? 'DOCS' : doctype === 'FORM9DOCS' ? 'FORM9DOCS' :'DOCS');
    let uid = (usertype !== 'applicant') ? id : userdetails.id;
    const handleFilterChange = (e) => {
        const value = e.target.value.toLowerCase();
        setFilter(value);
        const filtered = mydocs.filter(item =>
            item.title.toLowerCase().includes(value)
            // Add more conditions based on your data structure
        );
        setFilteredData(filtered);
    };
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Validate email
        if (!values.title.trim()) {
            newErrors.title = 'Title is required';
            isValid = false;
        } else {
            newErrors.title = '';
        }

        // Validate password
        if (!values.document.trim()) {
            newErrors.document = 'Document is required';
            isValid = false;
        } else {
            newErrors.password = '';
        }
        setMessage('');
        setErrors(newErrors);
        return isValid;
    };
      
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value})
    }
    function fetchItems(){
        if(usertype === 'applicant'){
            var url = `${usertype}/documentsView/${uid}/${docstype}`;
        }else{
            var url = `${usertype}/documentsView/${uid}/${docstype}?userid=${userdetails.id}`;
        }
        http.get(url)
        .then((response) => {
            //console.log(response.data);
            setMydocs(response.data.mydocs);
            setUsername(response.data.username);
            setFilteredData(response.data.mydocs);
            setMessage('');
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    useEffect(() => {
        fetchItems();
        
    }, []);
    const handleSubmit = async(e) =>{
        e.preventDefault();
        //validation(values);
        let title = values.title;
        let document = e.target.document.files[0];
        let documenttype = e.target.documenttype.value;
        let uid = e.target.uid.value;
        let userid = userdetails.id;
        const newErrors = { ...errors };
        
        if (validateForm()) {
            setLoader(true);
          http.post(`${usertype}/documentupload`,{title:title,document:document,documenttype:documenttype,uid:uid}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              e.target.reset();
              setMessage('FIle uploaded successfully!')
              setLoader(false);
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
              newErrors.title = error.response.data?.error?.title;
              newErrors.document = error.response.data?.error?.document;
              setErrors(newErrors);
            }
            if(error.response.status==401){
              newErrors.message = error.response.data?.error;
              setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
    //Download
    const handleCheckboxChange = (value) => {
        // Update the selected checkboxes array when a checkbox is checked or unchecked
        setSelectedCheckboxes((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected, value]
        );
    };
    const handleAllCheckboxChange = () => {
        setAllChecked(!allChecked);
        setSelectedCheckboxes(filteredData.map(li => li.documents));
        if (allChecked) {
            setSelectedCheckboxes([]);
        }
    };
    const handleDownload = () =>{
        http.post(`${usertype}/downloadAppFiles`,{files:selectedCheckboxes}, {
            responseType: 'blob',
        })
        .then((response) => {
            //console.log(response);
            let filename = (selectedCheckboxes.length === 1) ? selectedCheckboxes : 'download.zip';
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url; //''
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    const handleDelete = () =>{
        http.post(`${usertype}/deletedocument`,{documentid:selectedid})
        .then((response) => {
            //console.log(response); 
            fetchItems();
            handleCloseModal();            
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    const handleShowModal = (id) => {
        setSelectedid(id);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedid(null);
        setShowModal(false);
    };
  return (
    <div className="container-fluid">
        <div className="row mb-2 ">
            <div className="row">
                <div className="col-md-4">
                    <nav aria-label="breadcrumb">
                        { usertype === 'applicant' ?
                        <ol className="breadcrumb p-0">
                            <li className="breadcrumb-item "><a href={dashboard}>Dashboard</a></li>
                            <li className="breadcrumb-item" aria-current="page">{props.pageview}</li>
                        </ol>
                        :
                        <ol className="breadcrumb p-0">
                            <li className="breadcrumb-item "><a href={dashboard}>Dashboard</a></li>
                            <li className="breadcrumb-item ">
                                { purl === 'DOCS' && <a href={`/${usertype}/applicants`}>View all Applicants</a> }
                                { purl === 'GCDOCS' && <a href={`/${usertype}/gc`}>View all GC</a> }
                                { purl === 'FORM9DOCS' && <a href={`/${usertype}/formnine`}>View all Form I9</a> }
                                { purl === 'tickets' &&<a href={`/${usertype}/tickets`}> View all Tickets</a> }
                                { purl === 'premium' && <a href={`/${usertype}/premiumtickets`}>View all Premium Tickets</a>  }
                                { purl === 'reguar' && <a href={`/${usertype}/regulartickets`}>View all Regular Tickets</a>  }
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Documents Of {username.name}</li>
                        </ol>
                        }
                    </nav>
                </div>
                <div className="col-md-4 small  text-right">
                    <div className="row">
                        <input type="text" name="search" className="form-control searchEmail" placeholder="Search by title" value={filter}
                onChange={handleFilterChange}/>
                    </div>
                </div>
                <div className="col-md-4 text-right">
                    <a href="#" onClick={handleShow} className="text-primary pr-3"> <i className="bi bi-plus"></i> Add Documents</a>
                </div>
            </div>
            <div className="col-md-8"> </div>
        </div>
            <div className="row">
                <div className="col-md-6">&nbsp;</div>
                <div className="col-md-6 text-right">
                    <input type="hidden" name="user_id" value="1" />
                    <input type="hidden" className="form-control title" list="datalistOptions" id="exampleDataList" name="doc_type" value="DOCS" />
                    <button type="submit" className="btn btn-primary ml-2 mb-2" id="frm-download" name="merge"
                        value="download" onClick={handleDownload}>Download </button>
                </div>

            </div>
            <div className="row">
                <div className="col-md-12 pb-5">
                    <div className="card  mb-4">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table data-toggle="table" data-classes="table table-hover table-condensed"
                                    data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla"
                                    className="Tabla table table-hover table-condensed" data-page-length='10'>
                                    <thead>
                                        <tr>
                                            <th className="text-center"><input name="select_all" value="1" id="example-select-all"
                                                    type="checkbox" checked={allChecked} onChange={handleAllCheckboxChange}/></th>
                                            <th data-sortable="true">Document Title</th>
                                            <th data-sortable="true"><i className="bi bi-paperclip"></i></th>
                                            <th data-sortable="true">Document Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { filteredData.map(myDoc => (
                                            <tr key={myDoc.id}>
                                                <td className="text-center"><input type="checkbox" name="allmerge[]" checked={selectedCheckboxes.includes(myDoc.documents)}  onChange={() => handleCheckboxChange(myDoc.documents)} value={myDoc.documents} /></td>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-9" > {myDoc.title}</div>
                                                        <div className="col-3">
                                                            {(usertype==='applicant') ?
                                                            <Dropdown>
                                                                <Dropdown.Toggle as={CustomToggle} />
                                                                <Dropdown.Menu size="sm" title="">
                                                                    <Dropdown.Item href="#" onClick={()=>handleShowModal(myDoc.id)} >Delete</Dropdown.Item>                                                                    
                                                                </Dropdown.Menu>
                                                            </Dropdown> 
                                                            : 
                                                            ''
                                                            }
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><a href="#" onClick={() => handleModalShow(myDoc.documents)} ><img src={myDoc.documents.split('.').pop() == 'pdf' ? Pdf : Doc} />{myDoc.title}</a></td>
                                                <td>{myDoc.created_at}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>                
                    <div className="input_fields_wrap">
                        <div className="mb-2 col-md-12">
                            <p style={{ color: '#2D8BB4' }}><strong>Are you sure you want to delete ?</strong></p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="mb-2 col-md-12 text-center">
                        <button type="button" className="btn btn-secondary ml-2" onClick={handleCloseModal}>No</button> 
                        <button type="submit" onClick={handleDelete} className="btn btn-primary ml-2">Yes</button>
                    </div>
                </Modal.Footer>
            </Modal>
        <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Upload Document</Modal.Title>
            </Modal.Header>
            <form method="POST" onSubmit={handleSubmit}>
            <Modal.Body>                
                <div className="input_fields_wrap">
                    <div className="mb-2 col-md-12">
                        <label className="form-label small" style={{ color: '#2D8BB4' }}><strong>Document Title*</strong></label>
                        <input className="form-control title" list="datalistOptions"  name="title" onChange={handleChange} placeholder="Enter Title" />
                        <input type="hidden" className="form-control title" list="datalistOptions"  name="documenttype" value={docstype} />
                        <input type="hidden" className="form-control title" list="datalistOptions"  name="uid" value={uid} />
                        <div className="invalid-feedback small">{errors.title}</div>
                    </div>
                    <div className="mb-2 col-md-12 mt-2">
                        <label className="form-label small" style={{ color: '#2D8BB4' }}><strong>Upload File*</strong></label>
                        <br />
                        <div className="mb-3">
                            <input className="form-control file" type="file" name="document" onChange={handleChange} accept=".docx,application/pdf" id="formFile" />
                            <div className="invalid-feedback small">{errors.document}</div>
                            <div className="invalid-feedback small">{errors.message}</div>
                            {loader === true ? 
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            :
                            <div className="alert-success small">{message}</div>
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-2 col-md-12 text-center">
                    <button type="button" className="btn btn-secondary ml-2" >Cancel</button> 
                    <button type="submit" onClick={validateForm} className="btn btn-primary ml-2">Submit</button>
                </div>
            </Modal.Footer>
            </form>
        </Modal>
        <Viewdocument show={modalshow} filename={file} foldertype="mydocs" close={() => setModalshow(false)} />
    </div>
  )
}

export default Mydocuments