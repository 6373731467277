import React,{useState,useEffect} from 'react'
import AuthUser from '../AuthUser';
import Dropdown from "react-bootstrap/Dropdown";
import Modal from 'react-bootstrap/Modal';
function Attorneyslist(props) {
    let usertype = props.logintype;
    let pageview = props.pageview;
    let apiurl = props.apiurl;
    const [applicants, setApplicants] = useState([])
    const [applicantcount, setApplicantcount] = useState(0)
    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [selectedid, setSelectedid] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    let dashboard=`/${usertype}`;
    function fetchItems(){
        http.get(`${usertype}/${apiurl}/${userdetails.email}`)
        .then((response) => {
            console.log(response.data);
            setApplicants(response.data.attorneyslist);
            setApplicantcount(response.data.attorneyslist.length);
            setFilteredData(response.data.attorneyslist);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    useEffect(() => {
        fetchItems();
    }, []);
    const handleFilterChange = (e) => {
        const value = e.target.value.toLowerCase();
        setFilter(value);

        const filtered = applicants.filter(item =>
            item.name.toLowerCase().includes(value) || item.lastname.toLowerCase().includes(value) || item.email.toLowerCase().includes(value) || item.phone.toLowerCase().includes(value)
            // Add more conditions based on your data structure
        );

        setFilteredData(filtered);
    };
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
          href=""
          ref={ref}
          onClick={e => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {children}
          <span className="threedots" />
        </a>
    ));
    const handleDelete = () => {
        http.post(`${usertype}/deleteattorney`,{attorneyid:selectedid})
        .then((response) => {
            //console.log(response); 
            fetchItems(); 
            handleCloseModal();          
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    };

    const handleShowModal = (id) => {
        setSelectedid(id);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedid(null);
        setShowModal(false);
    };
  return (
    <div className="container-fluid">
        <div className="row mb-2 ">
            <div className="col-md-6">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item "><a href="/admin">Dashboard</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Attorneys</li>
                    </ol>
                </nav>
            </div>
            <div className="col-md-4 small  text-right">
                <div className="row">
                    <input  type="text" name="email" value={filter}
                onChange={handleFilterChange} className="form-control searchEmail" placeholder="Search by First name, last name, phone or email id..." />
                </div>
            </div>
            <div className="col-md-2 small  text-right">
                <h6 className="font-weight-bold">Attorney count :<span className="text-primary">{applicantcount}</span></h6>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 pb-5">
                <div className="card  mb-4">
                    <div className="card-body">
                         
                        <div className="table-responsive">
                            <table  data-toggle="table" className="table table-hover table-condensed" 
                                    data-striped="true"  data-sort-name="Quality"  data-sort-order="desc" id="Tabla" data-page-length='10'>
                                <thead>
                                    <tr>
                                        <th data-sortable="true">First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th className="text-center">Tel #</th>
                                        <th data-sortable="true" className="text-center">Country</th>
                                        <th data-sortable="true">Created Date / Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map(applicant =>(
                                        <tr key={applicant.id}>
                                            <td>
                                                <div className="row">
                                                    <div className="col-9">{applicant.name}</div>                                                    
                                                    <div className="col-3">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle} />
                                                            <Dropdown.Menu size="sm" title="">
                                                            <Dropdown.Item>Inactive</Dropdown.Item>
                                                            <Dropdown.Item>Active</Dropdown.Item>
                                                            <Dropdown.Item href="#?" onClick={()=>handleShowModal(applicant.id)}>Delete</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{applicant.lastname}</td>
                                            <td>{applicant.email}</td>
                                            <td className="text-center">+1 {applicant.phone}</td>
                                            <td className="text-center">USA</td>
                                            <td >{applicant.created_at}</td>
                                        </tr>
                                    ))

                                    }
                                </tbody>
                            </table>                
                        </div>
                    </div>
                </div>            
            </div>
        </div>
        <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>                
                <div className="input_fields_wrap">
                    <div className="mb-2 col-md-12">
                        <p style={{ color: '#2D8BB4' }}><strong>Are you sure you want to delete ?</strong></p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-2 col-md-12 text-center">
                    <button type="button" className="btn btn-secondary ml-2" onClick={handleCloseModal}>No</button> 
                    <button type="submit" onClick={handleDelete} className="btn btn-primary ml-2">Yes</button>
                </div>
            </Modal.Footer>
        </Modal>
    </div>
  )
}

export default Attorneyslist