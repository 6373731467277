import React,{useState,useEffect} from 'react'
import AuthUser from '../AuthUser';
import Dropdown from "react-bootstrap/Dropdown";
import Modal from 'react-bootstrap/Modal';

function Referalpolicylist(props) {
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    let usertype = props.logintype;
    let dashboard=`/${usertype}`;
    const [values,setValues]=useState({id:'',introduction:'',eligibility:'',incentives:'',referral:''});
    const [errors, setErrors] = useState({introduction:'',eligibility:'',incentives:'',referral:''});
    const [message,setMessage]= useState();
    const [showModal, setShowModal] = useState(false);
    function fetchItems(){
        http.get(`${usertype}/requestlist`)
          .then((response) => {
            console.log(response.data);
            setValues(response.data.referralpolicy);
          })
          .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
          });
    }
    useEffect(() => {
        fetchItems();
      }, []);
      const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        // Subject
        if (!values.introduction.trim()) {
            newErrors.introduction = 'Introduction is required';
            isValid = false;
        } else {
            newErrors.firstname = '';
        }
        // eventdate
        if (!values.eligibility.trim()) {
            newErrors.eligibility = 'Eligibility is required';
            isValid = false;
        } else {
            newErrors.eligibility = '';
        }
        // Subject
        if (!values.incentives.trim()) {
            newErrors.incentives = 'Incentive is required';
            isValid = false;
        } else {
            newErrors.incentives = '';
        } 
        if (!values.referral.trim()) {
            newErrors.referral = 'Referral is required';
            isValid = false;
        } else {
            newErrors.referral = '';
        }      
        setErrors(newErrors);
        setMessage('');
        return isValid;
    };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value})
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let id = values.id;
        let introduction = values.introduction;
        let eligibility = values.eligibility;
        let incentives = values.incentives;
        let referral = values.referral;
        let message = '';
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`${usertype}/updatereferralpolicy`,{id:id,introduction:introduction,eligibility:eligibility,incentives:incentives,referral:referral,userid:userdetails.id}).then((response)=>{
              console.log(response.data.message);
              setMessage('Referral policy updated successfully!');
              fetchItems();
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.introduction = error.response.data?.error?.introduction;
                newErrors.eligibility = error.response.data?.error?.eligibility;
                newErrors.incentives = error.response.data?.error?.incentives;
                newErrors.referral = error.response.data?.error?.referral;
                setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={e => {
            e.preventDefault();
            onClick(e);
            }}
        >
            {children}
            <span className="threedots" />
        </a>
        ));
    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
  return (
    <div className="container-fluid">
        <div className="col-md-10 m-auto p-0 pb-2 ">
            <div className='row'>
                <div className="col-md-6 text-left pull-left">
                    <h6 className="mb-2 font-weight-bold">Employee Referral</h6>
                </div>
                <div className="col-md-6 text-right pull-right">
                    <a href={dashboard} className="mb-2 font-weight-bold">Back</a>           
                </div>
            </div>
        </div>
        <div className="col-md-10 bg-white m-auto shadow-sm p-4 text-center" style={{ borderTop: '5px solid #DF8E2E' }}>
        
            <table data-toggle="table" data-classes="table table-hover table-condensed"
                data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla"
                className="Tabla table table-hover table-condensed" data-page-length='10'>
                <thead>
                    <tr>
                        <th className="text-center">Introduction</th>
                        <th data-sortable="true">Eligibility</th>
                        <th data-sortable="true">Incentives</th>
                        <th data-sortable="true">Referral</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key={values.id}>
                        <td>
                            <div className="row">
                                <div className="col-9">{ values.introduction}</div>                        
                                <div className="col-3">
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu size="sm" title="">
                                            <Dropdown.Item key={values.id} href="#" onClick={()=>handleShowModal(values.id)}>Edit</Dropdown.Item>                                                    
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </td>
                        <td>{ values.eligibility}</td>
                        <td>{ values.incentives}</td>
                        <td>{ values.referral}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Edit Referral Policy</Modal.Title>
            </Modal.Header>
            <form method="POST" onSubmit={handleSubmit}>
            <Modal.Body>                
            <div className="col-md-12 p-2 border customer_records" style={{ backgroundColor: '#F6F6F6' }}>
                    <div className="mb-3">
                        <textarea className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="introduction" rows="5" columns="5" value={values.introduction} onChange={handleChange}></textarea>
                        <div className="invalid-feedback small">{errors.introduction}</div>
                        <textarea className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="eligibility" rows="5" columns="5" value={values.eligibility} onChange={handleChange}></textarea>
                        <div className="invalid-feedback small">{errors.eligibility}</div>
                        <textarea className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="incentives" rows="5" columns="5" value={values.incentives} onChange={handleChange}></textarea>
                        <div className="invalid-feedback small">{errors.incentives}</div>                        
                        <textarea className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="referral" rows="5" columns="5" value={values.referral} onChange={handleChange}></textarea>
                        <div className="invalid-feedback small">{errors.referral}</div>                        
                        <div className="alert-success small">{message}</div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-2 col-md-12 text-center">
                    <button type="reset" className="btn btn-secondary ml-2" data-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary ml-2" onClick={validateForm}>Submit</button>
                </div>
            </Modal.Footer>
            </form>
        </Modal>
    </div>
  )
}

export default Referalpolicylist