import React,{useState,useEffect} from 'react'
import AuthUser from '../AuthUser';
function Payrollcalendar(props) {
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    let usertype = props.logintype;
    let dashboard=`/${usertype}`;
    const [payrollcalendar, setPayrollcalendar] = useState([]);
    useEffect(() => {
        http.get(`${props.logintype}/payrollcalendar/${userdetails.email}`)
          .then((response) => {
            //console.log(response.data);
            setPayrollcalendar(response.data.payrollcalendar);
          })
          .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
          });
      }, []);
  return (
    <div className="container-fluid">
        <div className="col-md-6 m-auto p-0 pb-2 ">
            <div className='row'>
                <div className="col-md-6 text-left pull-left">
                    <h6 className="mb-2 font-weight-bold">Payroll Calendar</h6>
                </div>
                <div className="col-md-6 text-right pull-right">
                    <a href={dashboard} className="mb-2 font-weight-bold">Back</a>           
                </div>
            </div>
        </div>
        <div className="col-md-6 bg-white m-auto shadow-sm p-4 text-center" style={{ borderTop: '5px solid #DF8E2E' }}>
        <table data-toggle="table" data-classes="table table-hover table-condensed"
            data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla"
            className="Tabla table table-hover table-condensed" data-page-length='10'>
            <thead>
                <tr>
                    <th className="text-center"></th>
                    <th data-sortable="true">Pay Period</th>
                    <th data-sortable="true">Pay Date</th>
                </tr>
            </thead>
            <tbody>
                {payrollcalendar.map(payroll => (
                <tr key={payroll.id}>
                    <td></td>
                    <td>{ payroll.paymonth}</td>
                    <td>{ payroll.paydate}</td>
                </tr>
                ))}
            </tbody>
        </table>
        </div>
    </div>
  )
}

export default Payrollcalendar