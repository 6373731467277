import React,{useState} from 'react'
import AuthUser from "../AuthUser";

function Myrequests() {
    const [values,setValues]=useState({subject:'',message:'',document:''});
    const [errors, setErrors] = useState({subject: '',message: '',document:'',successmessage:'' });
    const { http, getUser } = AuthUser();
    const [message,setMessage]= useState();
    let userdetails = getUser();
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Subject
        if (!values.subject.trim()) {
            newErrors.subject = 'Subject is required';
            isValid = false;
        } else {
            newErrors.title = '';
        }
        // Message
        if (!values.message.trim()) {
            newErrors.message = 'Message is required';
            isValid = false;
        } else {
            newErrors.message = '';
        }
        // File Upload
        if (!values.document.trim()) {
            newErrors.document = 'Document is required';
            isValid = false;
        } else {
            newErrors.document = '';
        }

        setErrors(newErrors);
        setMessage('');
        return isValid;
    };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let subject = values.subject;
        let message = values.message;
        let document = e.target.document.files[0];
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`applicant/postrequest/${userdetails.email}`,{subject:subject,file:document,message:message,userid:userdetails.id}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              setValues({
                subject: '',
                document: '',
                message: '',
              });
              e.target.reset();
              setMessage('Request sent successfully!');
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.subject = error.response.data?.error?.subject;
                newErrors.document = error.response.data?.error?.document;
                newErrors.message = error.response.data?.error?.message;
                setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
  return (
    <div className="container-fluid">
        <div className="col-md-4 m-auto p-0 pb-2 ">
            <div className='row'>
                <div className="col-md-6 text-left pull-left">
                    <h6 className="mb-2 font-weight-bold">My Request</h6>
                </div>
                <div className="col-md-6 text-right pull-right">
                    <a href="/applicant" className="mb-2 font-weight-bold">Back</a>           
                </div>
            </div>
        </div>
        <div className="col-md-4 bg-white m-auto shadow-sm p-4" style={{ borderTop: '5px solid #DF8E2E' }}>
            <form  method="POST" onSubmit={handleSubmit}>
                <div className="alert-success small"></div>
                <div className="row">
                    <div className="col-lg-12 mt-4">
                        <input type="text" className="form-control textOnly" placeholder="Subject" name="subject" onChange={handleChange} />
                        <div className="invalid-feedback small">{errors.subject}</div>
                    </div>
                    <div className="col-lg-12 mt-4">
                        <textarea className="form-control" id="exampleFormControlTextarea1" name="message" rows="5" onChange={handleChange} placeholder="Message..."></textarea>
                        <div className="invalid-feedback small">{errors.message}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 mt-4">
                        <div className="form-group">
                            <input className="form-control file" type="file" name="document" onChange={handleChange} accept=".docx,application/pdf" id="formFile" />
                            <div className="invalid-feedback small">{errors.document}</div>
                            <div className="invalid-feedback small">{errors.successmessage}</div>
                            <div className="alert-success small">{message}</div>
                        </div>
                    </div>
                </div>
                
                <div className="d-grid gap-2 col-8 mx-auto mt-3">
                    <button className="btn text-white" type="submit" onClick={validateForm} style={{ backgroundColor: '#81A744' }}>Submit</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default Myrequests