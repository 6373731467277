import React,{useState} from 'react'
import axios from 'axios';
import AuthUser from '../AuthUser';
import '../css/H1B_Applicants.css';

function Login(props) {
    let forgotpassword = props.logintype==='applicant' ? '/applicantforgot' : props.logintype === 'employer' ? '/employerforgot' : props.logintype === 'attorney' ? '/attorneyforgot' : '';
    let usertype = props.logintype;
    const {http,setToken} = AuthUser();
    const [values,setValues]=useState({email:'',password:''});
    const [errors, setErrors] = useState({email: '',password: '',message:'' });
    const [message, setMessage] = useState();
    const [submitted, setsubmitted] = useState(false);
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Validate email
        if (!values.email.trim()) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else  if(!emailRegex.test(values.email)){
            newErrors.email = 'Email not valid';
            isValid = false;
        }else {
            newErrors.email = '';
        }

        // Validate password
        if (!values.password.trim()) {
            newErrors.password = 'Password is required';
            isValid = false;
        } else {
            newErrors.password = '';
        }

        setErrors(newErrors);
        return isValid;
    };
      
    function handleChange(e){
        setValues({...values,[e.target.name]:e.target.value})
    }
      
    const handleSubmit = async (e) =>{
        e.preventDefault();
        //validation(values);
        let email = values.email;
        let password = values.password;
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`${usertype}/login`,{email:email,password:password}).then((response)=>{
              setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              //console.log(response.data);
              
          }).catch(function (error) {
            // handle error
            console.log(error);
            //console.log(error.response.data.error);
            
            if(error?.response?.status==403){
              newErrors.email = error.response.data?.error?.email;
              newErrors.password = error.response.data?.error?.password;
              setErrors(newErrors);
            }
            if(error.response.status==401){
              newErrors.message = error.response.data?.error;
              setErrors(newErrors);
            }
            
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
  return (
    <form method="POST" onSubmit={handleSubmit} id="loginpostCheck" >                                
        <div className="col-md-12 ">
            <div className="input-container ic1">
                <input type="email" className="form-control rounded-0 mt-2 mb-2 input" id="Email" onChange={handleChange}   placeholder=" " name="email" value={values.email} />
                <div className="cut"></div>
                <label  className="placeholder">Email *</label>
                <div className="invalid-feedback small">{errors.email}</div>
            </div>
            <div className="input-container ic1">
                <input type="password" id="pswd" className="form-control rounded-0 mt-2 input" onChange={handleChange} placeholder=" " name="password" value={values.password} />
                <div className="cut2"></div>
                <label className="placeholder">Password *</label>
                <div className="invalid-feedback small">{errors.password}</div>
                <div className="invalid-feedback small">{errors.message}</div>
            </div>
        </div>
        <div className="col-md-12 text-center d-grid">
            <button type="submit" className="btn text-white mt-2 rounded-0"
                style={{ backgroundColor: '#2488B3' }} onClick={validateForm} disabled={submitted} ><strong>Login</strong></button>
        </div>
        <div className="row mt-3">
            <div className="col-md-6"> </div>
            <div className="col-md-6" style={{ textAlign: 'right', fontSize: '12px' }}> <a
                    href={forgotpassword} className="small">Forgot Password?
                </a> </div>
        </div>
        {props.logintype==='applicant' && 
        <div className="col-md-12" style={{ textAlign: 'right', fontSize: '12px' }}> Don't have an
            account? <a href="/applicantregister" className="small">Create an
                Account! </a>
        </div>
        }
    </form>
  )
}

export default Login