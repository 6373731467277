import React,{useState,useEffect} from 'react'
import AuthUser from '../AuthUser';
import { useNavigate } from 'react-router-dom';
function Tickets(props) {
    let usertype = props.logintype;
    const navigate = useNavigate();
    const [ticketsllist, setTicketsllist] = useState([])
    const [message,setMessage]=useState();
    //const [selectedOption, setSelectedOption] = useState(null);
    const [textboxesEnabled, setTextboxesEnabled] = useState([]);
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    const isAuthenticated = (userdetails !== '') ? true : false;
    const handleRadioChange = (index) => {
        //setSelectedOption(index);
        const updatedTextboxesEnabled = Array(textboxesEnabled.length).fill(false);
        updatedTextboxesEnabled[index] = true;
        setTextboxesEnabled(updatedTextboxesEnabled);    
    };

    useEffect(() => {
        if(isAuthenticated){
            http.get(`applicant/mytickets/${userdetails.email}`)
            .then((response) => {
                
                setTicketsllist(response.data.ticketslist);
            })
            .catch(function (error) {
                // handle error
                console.log(error.response.data.error);
            });
        }else{
            navigate("/")
        }
            
    }, []);
    const handleSubmit = async (e) =>{
        e.preventDefault();
        let Tickettitle = e.target.raiseticket.value;
        let Tickettype = e.target.processtype.value;
        
        if (Tickettitle !='' && Tickettype != '') {
          http.post(`${usertype}/raiseticket`,{email:userdetails.email,tickettitle:Tickettitle,tickettype:Tickettype}).then((response)=>{              
              
            console.log(response.data.message);
            e.target.reset();
            setMessage('Ticket raised successfully!')
          }).catch(function (error) {
            
          })
        } else {
          // Form is not valid, display error messages
    
        }
        //console.log()
    }
  return (
    <div className="container-fluid">
        <div className="row mb-2 ">

            <div className="col-md-6">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb p-0">
                        <li className="breadcrumb-item "><a href="/applicant">Dashboard</a></li>
                        <li className="breadcrumb-item "><a>View tickets</a></li>
                    </ol>
                </nav>
            </div>
        </div>
        <div className="col-lg-9 m-auto  text-dark">
            <div className="row">
                <div className="col-md-2">
                    <ul className="list-group list-group-flush bg-transparent font-weight-bold small navbar-nav-main">
                        <li className="list-group-item bg-transparent border-0">
                            <a href="/applicant/mytickets" id="all" className="ticketShow text-dark text-decoration-none">My Tickets</a>
                        </li>
                        <li className="list-group-item bg-transparent border-0">
                            <a href="/applicant/opentickets" id="open" className="ticketShow text-dark text-decoration-none">Open</a>
                        </li>
                        <li className="list-group-item bg-transparent border-0">
                            <a href="/applicant/approvedtickets" id="approved" className="ticketShow text-dark text-decoration-none">Approved</a>
                        </li>
                        <li className="list-group-item bg-transparent border-0">
                            <a href="/applicant/worktickets" id="work" className="ticketShow text-dark text-decoration-none">Work In Progress</a>
                        </li>
                        <li className="list-group-item bg-transparent border-0">
                            <a href="/applicant/closedtickets" id="close" className="ticketShow text-dark text-decoration-none">Close</a>
                        </li>
                    </ul>                
                </div>
                <div className="col-md-10 bg-white shadow-sm pb-5">
                    <div className="container-fluid">
                        <div className="col-md-12 bg-white m-auto shadow-sm p-4 pb-5" style={{ borderTop: '5px solid #DF8E2E' }}>
                            <form method="POST" onSubmit={handleSubmit}>
                                <div className="row">
                                    <table  id="reqtablenew" className="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <label className="form-label small" style= {{ color: '#2D8BB4' }}><strong>Submit ticket for:</strong></label>
                                                </th>
                                                <th>
                                                    <label className="form-label small" style={{ color: '#2D8BB4' }}><strong>Processing Type:</strong></label>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ticketsllist.map(ticket =>(
                                            <tr key={ticket.id}>
                                                <td>
                                                    <div className="form-check form-check-inline ml-4">
                                                        <input className="form-check-input actionCheck" type="radio" name="raiseticket" value={ticket.title} onChange={() => handleRadioChange(ticket.id)}/>
                                                        <label className="form-check-label" >{ticket.title}</label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-check form-check-inline ml-4 actionType">  
                                                        {ticket.processing_type!='' ? ticket.processing_type.split(',').map((ticketprocess, i) =>(
                                                            <div className="form-check form-check-inline ml-4 actionType" key={i}>
                                                                <input className="form-check-input " type="radio" disabled={!textboxesEnabled[ticket.id]} name="processtype" value={ticket.processing_type}  />
                                                                <label className="form-check-label" key={i} >{ticket.processing_type}</label>&nbsp;&nbsp;
                                                            </div>
                                                        )) :(                                                      
                                                            <div className="form-check form-check-inline ml-4 actionType">
                                                                <input className="form-check-input " type="radio" disabled={!textboxesEnabled[ticket.id]} name="processtype" id="inlineRadio1_{{ $ticket->id }}" value="regular" />
                                                                <label className="form-check-label" >Regular</label>&nbsp;&nbsp;
                                                                <input className="form-check-input " type="radio" disabled={!textboxesEnabled[ticket.id]} name="processtype" id="inlineRadio" value="premium" />
                                                                <label className="form-check-label" >Premium</label>
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                            
                                            ))}
                                            
                                        </tbody>
                                    </table>
                                    
                                    <div className="col-12 mt-3 text-center">
                                        <button className="btn text-white btn-secondary" type="reset" >Cancel</button>&nbsp;
                                        <button className="btn text-white" type="submit" style={{ backgroundColor: '#81A744' }} >Submit</button>
                                    </div>  
                                    <div className="alert-success small">{message}</div>                                   
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Tickets