import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import AuthUser from "../AuthUser";
import Doc from '../images/doc.png'
import Pdf from '../images/pdf.png'
import Viewdocument from '../Common/Viewdocument';
import Documentupload from './Documentupload';
import Spinner from 'react-bootstrap/Spinner';
import Dropdown from "react-bootstrap/Dropdown";

function Sampledocuments(props) {
    let usertype = props.logintype;
    let pageview = props.pageview;
    let doctype = props.doctype;
    let apiurl = props.apiurl;
    const[loader,setLoader]=useState(false);
    const [sampledocs, setSampledocs] = useState([]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [values,setValues]=useState({title:'',document:''});
    const [errors, setErrors] = useState({title: '',document: '',message:'' });
    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [file, setFile] = useState(false);
    const [show, setShow] = useState(false);
    const [modalshow, setModalshow] = useState(false);
    const [allChecked, setAllChecked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedid, setSelectedid] = useState(null);
    //const handleClose = () => setShow(false);
    const handleShow = () => setShow(true); 
    const handleModalShow = (filename) =>{
        setModalshow(true);
        setFile(filename);
    } 
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    let dashboard=`/${usertype}`;
    const fetchItems = async () => {
        http.get(`${usertype}/${apiurl}/${userdetails.email}`)
        .then((response) => {
            console.log(response.data);
            setSampledocs(response.data.sampledocs);
            setFilteredData(response.data.sampledocs);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
      };
    useEffect(() => {
        fetchItems();
    }, []);
    const handleFilterChange = (e) => {
        const value = e.target.value.toLowerCase();
        setFilter(value);

        const filtered = sampledocs.filter(item =>
            item.title.toLowerCase().includes(value)
            // Add more conditions based on your data structure
        );

        setFilteredData(filtered);
    };
    const handleCheckboxChange = (value) => {
        // Update the selected checkboxes array when a checkbox is checked or unchecked
        setSelectedCheckboxes((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected, value]
        );
    };
    const handleAllCheckboxChange = () => {
        setAllChecked(!allChecked);
        setSelectedCheckboxes(filteredData.map(li => li.documents));
        if (allChecked) {
            setSelectedCheckboxes([]);
        }
    };
    //Download
    const handleDownload = () =>{
        setLoader(true)
        http.post(`${usertype}/downloadFiles`,{files:selectedCheckboxes}, {
            responseType: 'blob',
        })
        .then((response) => {
            //console.log(response);
            let filename = (selectedCheckboxes.length === 1) ? selectedCheckboxes : 'download.zip';
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url; //''
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoader(false)
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    const handleDelete = () =>{
        http.post('/admin/deletedocument',{admindocid:selectedid})
        .then((response) => {
            fetchItems();
            handleCloseModal(); 
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    const handleShowModal = (id) => {
        setSelectedid(id);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedid(null);
        setShowModal(false);
    };
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
        e.preventDefault();
        onClick(e);
        }}
    >
        {children}
        <span className="threedots" />
    </a>
    ));
  return (
    <div className="container-fluid">
    <div className="row mb-2 ">
        <div className="col-md-4">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb p-0">
                    <li className="breadcrumb-item "><a href={dashboard}>Dashboard</a></li>
                    <li className="breadcrumb-item active" aria-current="page">{pageview}</li>
                </ol>
            </nav>
        </div>
        <div className="col-md-4 small  text-right">
            <div className="row">
                <input type="text" name="title" className="form-control searchEmail" placeholder="Search by title" value={filter}
                onChange={handleFilterChange} />
            </div>

        </div>
        <div className="col-md-4 text-right">
            { usertype !== 'applicant' && <a href="javascript:void(0);" onClick={handleShow} className="text-primary pr-3"> <i className="bi bi-plus"></i> Add Documents</a> }
        </div>
    </div>
    <div className="row">
        <div className="col-md-6">&nbsp;</div>
        <div className="col-md-6 text-right">
            <input className="form-check-input" type="hidden" name="allmerge[]" />
            <input type="hidden" name="user_id" value="1" />
            <input type="hidden" className="form-control title" list="datalistOptions" id="exampleDataList" name="document_type" value="Sample" />
            <button type="submit" className="btn btn-primary ml-2 mb-2" id="frm-download" name="merge" onClick={handleDownload}
                value="download">Download </button>
        </div>

    </div>
    <div className="row">
        <div className="col-md-12 pb-5">
            <div className="card  mb-4">
                <div className="card-body">
                    {loader === true &&
                        <Spinner animation="border" className='mx-auto' role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    }        
                    <div className="table-responsive">
                        <table data-toggle="table" data-classes="table table-hover table-condensed"
                            data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla"
                            className="Tabla table table-hover table-condensed" data-page-length='10'>
                            <thead>
                                <tr>
                                    <th className="text-center" >
                                        <input name="select_all" id="example-select-all" type="checkbox" checked={allChecked} onChange={handleAllCheckboxChange}/>
                                    </th>
                                    <th data-sortable="true">Document Title</th>
                                    <th data-sortable="true"><i className="bi-paperclip"></i></th>
                                    <th data-sortable="true">Document Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map(sampleDoc => (
                                    
                                    <tr key={sampleDoc.id}>
                                        <td className="text-center"><input type="checkbox" name="allmerge[]" checked={selectedCheckboxes.includes(sampleDoc.documents)}  onChange={() => handleCheckboxChange(sampleDoc.documents)} value={sampleDoc.documents} /></td>
                                        <td>
                                        <div className="row">
                                            <div className="col-9">{sampleDoc.title}</div>
                                            { usertype === 'admin' &&                                                   
                                            <div className="col-3">
                                                <Dropdown>
                                                    <Dropdown.Toggle as={CustomToggle} />
                                                    <Dropdown.Menu size="sm" title="">
                                                        <Dropdown.Item key={sampleDoc.id} href="javascript:void(0);" onClick={()=>handleShowModal(sampleDoc.id)}>Delete</Dropdown.Item>                                                    
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            }
                                        </div>
                                        </td>
                                        <td><a href="javascript:void(0);" onClick={() => handleModalShow(sampleDoc.documents)} ><img src={sampleDoc.documents.split('.').pop() == 'pdf' ? Pdf : Doc} />{sampleDoc.title}</a></td>
                                        <td>{sampleDoc.created_at}&nbsp;{}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Documentupload show={show} close={() => { setShow(false); fetchItems(); } } usertype={usertype} documenttype={doctype}/>
    <Viewdocument show={modalshow} filename={file} foldertype="admindocs" close={() => setModalshow(false)} />
    <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>                
            <div className="input_fields_wrap">
                <div className="mb-2 col-md-12">
                    <p style={{ color: '#2D8BB4' }}><strong>Are you sure you want to delete ?</strong></p>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <div className="mb-2 col-md-12 text-center">
                <button type="button" className="btn btn-secondary ml-2" onClick={handleCloseModal}>No</button> 
                <button type="submit" onClick={handleDelete} className="btn btn-primary ml-2">Yes</button>
            </div>
        </Modal.Footer>
    </Modal>
</div>
  )
}

export default Sampledocuments