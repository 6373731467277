import React,{useState,useEffect} from 'react'
import AuthUser from '../AuthUser';
import Modal from 'react-bootstrap/Modal';
function Applicantstatus() {
    const { http, getUser } = AuthUser();
    const [applicants, setApplicants] = useState([])
    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const[status,setStatus]=useState();
    const[message,setMessage]=useState();
    const[error,setError]=useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedid, setSelectedid] = useState(null);
    let userdetails = getUser();
    function fetchItems(){
        http.get('admin/applicantstatuslist')
        .then((response) => {
            setApplicants(response.data.applicantstatus);
            setFilteredData(response.data.applicantstatus);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    useEffect(() => {
        fetchItems();
    }, []);
    const handleFilterChange = (e) => {
        const value = e.target.value.toLowerCase();
        setFilter(value);
        const filtered = applicants.filter(item =>
            item.status_name.toLowerCase().includes(value)
            // Add more conditions based on your data structure
        );

        setFilteredData(filtered);
    };
    function handleChange(e){
        setStatus(e.target.value)
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        //validation(values); 
        let statusname = status; 
        if (statusname === '') {
            setError('Status name is required');
        } else {
            setError('');
            http.post('admin/addstatus',{status_name:statusname}).then((response)=>{
                //setToken(response.data.userdetail,response.data.token,usertype);
                //console.log(response.status);
                //console.log(response.data.message);
                e.target.reset();
                fetchItems();
                setMessage(response.data.message);
                
            }).catch(function (error) {
              if(error.response.status===403){
                setError(error.response.data?.error?.status_name);
              }
              if(error.response.status===401){
                setError(error.response.data?.error);
              }
            })
        } 
    }
    const handleDelete = () => {
        http.post('admin/deleteappstatus',{tickettypeid:selectedid})
        .then((response) => {
            //console.log(response); 
            fetchItems();
            handleCloseModal();           
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    };

    const handleShowModal = (id) => {
        setSelectedid(id);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedid(null);
        setShowModal(false);
    };
  return (
    <div className="container-fluid">
        <div className="row mb-2 ">
        <div className="col-md-4">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                        <li className="breadcrumb-item "><a href="/admin">Dashboard</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Applicant Status List</li>
                </ol>
            </nav>
        </div>
        <div className="col-md-4 small  text-right">
            <div className="row">
                <input type="text" name="email" value={filter}
                onChange={handleFilterChange}  className="form-control searchTicket" placeholder="Search by applicant status..." />
            </div>
        </div>
        <div className="col-md-4 text-right">
                <a className="text-primary pr-3" onClick={handleShow} href="#" data-toggle="modal" data-target="#addticket"> <i className="bi bi-plus"></i> Add Applicant Status </a> 
            </div>
        </div>
        <div className="row">
        <div className="col-md-9 bg-white shadow-sm p-0 mx-auto">
            <div className="card  mb-4">
            <div className="card-body">
                <div className="table-responsive">
                <table  data-toggle="table" className="Tabla table table-stripped table-hover table-condensed"  data-striped="true"  data-sort-name="Quality"  data-sort-order="desc" id="Tabla" data-page-length='10'>
                    <thead>
                    <tr>
                        <th>Applicant Status</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        {filteredData.map(applicant =>(
                            <tr key={applicant.id}>
                                <td>{applicant.status_name}</td>
                                <td><a href="#" onClick={()=>handleShowModal(applicant.id)}>Delete</a></td>
                            </tr>
                        ))

                        }
                    </tbody>
                </table>                
                </div>
            </div>
            </div>
        </div>
        </div>
        <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Add Applicant Status</Modal.Title>
            </Modal.Header>
            <form method="POST" onSubmit={handleSubmit}>
            <Modal.Body className='modal-body'>                
                    <div className="mb-3">
                        <label for="exampleFormControlInput1" className="form-label">Status Name</label>
                        <input type="text" className="form-control rounded-2" name="statusname" value={status} id="Technology" onChange={handleChange} placeholder="Applicant status name" />
                        <div className="invalid-feedback small">{error}</div>
                    </div>
                    <div className="alert-success small">{message}</div>
                    <span className="form-control1"></span>
            </Modal.Body>
            <Modal.Footer>
                <button type="submit" class="btn btn-primary rounded-0">Submit</button>
            </Modal.Footer>
            </form>
        </Modal>
        <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>                
                <div className="input_fields_wrap">
                    <div className="mb-2 col-md-12">
                        <p style={{ color: '#2D8BB4' }}><strong>Are you sure you want to delete ?</strong></p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-2 col-md-12 text-center">
                    <button type="button" className="btn btn-secondary ml-2" onClick={handleCloseModal}>No</button> 
                    <button type="submit" onClick={handleDelete} className="btn btn-primary ml-2">Yes</button>
                </div>
            </Modal.Footer>
        </Modal>
    </div>
  )
}

export default Applicantstatus