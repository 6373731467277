import React from 'react';
import { Navbar } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Routes, Route, Link, Router, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import AuthUser from '../AuthUser';
import Headerlogo from '../images/H1B_Applicants_logo.png';
import AdminDashboard from '../Admin/AdminDashboard';
import Myprofile from '../Common/Myprofile';
import Changepassword from '../Common/Changepassword';
import Ticketslist from '../Common/Ticketslist';
import SampleDocuments from '../Common/Sampledocuments';
import Lcalist from '../Employer/Lcalist';
import Mydocuments from '../Applicants/Mydocuments';
import Applicantslist from '../Common/Applicantslist';
import Employerslist from '../Admin/Employerslist';
import Attorneyslist from '../Admin/Attorneyslist';
import Tickettype from '../Admin/Tickettype';
import Pointofcontact from '../Admin/Pointofcontact';
import Resetpassword from '../Common/Resetpassword';
import Usersettings from '../Admin/Usersettings';
import Applicantstatus from '../Admin/Applicantstatus';
import Newemployeedocumentation from '../Applicants/Newemployeedocumentation';
import Paychexlogin from '../Applicants/Paychexlogin';
import Payrollcalendar from '../Applicants/Payrollcalendar';
import Eventslist from '../Employer/Eventslist';
import Suggestionlist from '../Employer/Suggestionlist';
import Feedbacklist from '../Employer/Feedbacklist';
import Timesheetdetails from '../Employer/Timesheetdetails';
import Performancereviewlist from '../Employer/Performancereviewlist';
import Timesheetdetailreport from '../Employer/Timesheetdetailreport';
import Requestslist from '../Employer/Requestslist';
import Referalpolicylist from '../Employer/Referalpolicylist';
function AdminNav() {
    const {token,logout,getUser} = AuthUser();
    let userdetails = getUser();
    let loguser = userdetails.name+" "+userdetails.lastname;
    const logoutUser = () => {
        if(token !== undefined){
            logout();
        }
    }
    const isAuthenticated = (userdetails !== '') ? true : false;
  return (
    <>
        <Navbar expand="md" className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <Navbar.Brand href="/admin"><img src={Headerlogo} width="80%" alt="H1B Applicants" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
            <NavDropdown className="navbar-nav ml-auto" title={loguser} id="navbarScrollingDropdown">
                <NavDropdown.Item href="/admin/myprofile"><i className="bi-person-fill"></i>My Profile</NavDropdown.Item>
                <NavDropdown.Item href="/admin/applicantstatus"><i className="bi-person-fill"></i>Applicant Status</NavDropdown.Item>
                <NavDropdown.Item href="/admin/usersettings"><i className="bi-person-fill"></i>User Settings</NavDropdown.Item>
                { /* <NavDropdown.Item href="/admin/changepassword"><i className="bi-key-fill"></i>Add menu item</NavDropdown.Item> */}
                {/*<NavDropdown.Item href="/admin/myprofile"><i className="bi-person-fill"></i>Add ticket list</NavDropdown.Item>*/}
                <NavDropdown.Item href="/admin/changepassword"><i className="bi-key-fill"></i>Change Password</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logoutUser}>Logout</NavDropdown.Item>
            </NavDropdown>
            </Navbar.Collapse>
        </Navbar>
        <Routes>
            <Route path="/admin" element={ isAuthenticated ? <AdminDashboard logintype="applicant"/> : <Navigate to="/" replace /> } />
            
            <Route path="/admin/changepassword" element={isAuthenticated ? <Changepassword logintype="admin"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/myprofile" element={ isAuthenticated ? <Myprofile logintype="admin" /> : <Navigate to="/" replace /> } />
            <Route path="/admin/usersettings" element={isAuthenticated ? <Usersettings /> : <Navigate to="/" replace /> } />
            <Route path="/admin/applicantstatus" element={isAuthenticated ? <Applicantstatus /> : <Navigate to="/" replace /> } />
            <Route path="/admin/employers" element={isAuthenticated ? <Employerslist logintype="admin" pageview="Employers" apiurl="employersapi"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/attorneys" element={ isAuthenticated ? <Attorneyslist logintype="admin" pageview="Attorneys" apiurl="attorneysapi"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/applicants" element={isAuthenticated ? <Applicantslist logintype="admin" pageview="IT Consultants" apiurl="applicantsapi"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/sampledocuments" element={isAuthenticated ? <SampleDocuments logintype="admin" pageview="Sample Documents" doctype="Sample" apiurl="sampledocsapi" /> : <Navigate to="/" replace /> } />
            <Route path="/admin/publicDocuments" element={isAuthenticated ? <SampleDocuments logintype="admin" pageview="Public Access Files" doctype="PUBLIC"  apiurl="publicdocsapi" /> : <Navigate to="/" replace /> } />
            <Route path="/admin/publicCompliance" element={isAuthenticated ? <SampleDocuments logintype="admin" pageview="Public Access Files Compliance" doctype="PUBLICCOMP"  apiurl="publiccompdocsapi"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/h1bQuestionaire" element={isAuthenticated ? <SampleDocuments logintype="admin" pageview="H1b Questionaire" doctype="H1BQ" apiurl="h1bdocsapi" /> : <Navigate to="/" replace /> }/>
            <Route path="/admin/uscisDocuments" element={isAuthenticated ? <SampleDocuments logintype="admin" pageview="USCIS Documents" doctype="USCIS" apiurl="uscisdocsapi"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/msaDocuments" element={isAuthenticated ? <SampleDocuments logintype="admin" pageview="MSA Documents" doctype="MSA" apiurl="msadocsapi"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/sowDocuments" element={isAuthenticated ? <SampleDocuments logintype="admin" pageview="SOW Documents" doctype="SOW" apiurl="sowdocsapi"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/lca" element={isAuthenticated ? <Lcalist logintype="admin" apiurl="lcalist"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/tickettype" element={isAuthenticated ? <Tickettype logintype="admin" pageview="Ticket Type" apiurl="tickettypeapi"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/pointofcontact" element={isAuthenticated ? <Pointofcontact logintype="admin" pageview="Point of contact" apiurl="pointofcontactapi"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/tickets" element={isAuthenticated ? <Ticketslist logintype="admin" pageview="Tickets" apiurl="ticketslistapi"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/opentickets" element={isAuthenticated ? <Ticketslist logintype="admin" pageview="Open Tickets" apiurl="openticketsapi"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/closedtickets" element={isAuthenticated ? <Ticketslist logintype="admin" pageview="Closed Tickets" apiurl="closedticketsapi"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/premiumtickets" element={isAuthenticated ? <Ticketslist logintype="admin" pageview="Premium Tickets" apiurl="premiumticketsapi"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/Regulartickets" element={isAuthenticated ? <Ticketslist logintype="admin" pageview="Regular Tickets" apiurl="regularticketsapi"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/mydocs/:id/:doctype" element={isAuthenticated ? <Mydocuments logintype="admin" apiurl="documentview"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/resetpassword/:token/:email" element={isAuthenticated ? <Resetpassword logintype="admin" pageview="Reset Password" apiurl="passwordUpdate" /> : <Navigate to="/" replace /> } />
            <Route path="/admin/createpassword/:email" element={isAuthenticated ? <Resetpassword logintype="admin" pageview="Create Password" apiurl="updatePassword" /> : <Navigate to="/" replace /> } />
            <Route path="/admin/newempdocuments" element={isAuthenticated ? <Newemployeedocumentation logintype="admin" apiurl="adminnewdocuments" /> : <Navigate to="/" replace /> }/>
            <Route path="/admin/performancereview" element={isAuthenticated ? <Performancereviewlist logintype="admin" apiurl="performancereview" /> : <Navigate to="/" replace /> } />
            <Route path="/admin/timesheet" element={isAuthenticated ? <Timesheetdetails logintype="admin"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/timesheetreport/:month" element={isAuthenticated ? <Timesheetdetailreport logintype="admin" apiurl="timesheetreport" /> : <Navigate to="/" replace /> } />
            <Route path="/admin/events" element={isAuthenticated ? <Eventslist logintype="admin" /> : <Navigate to="/" replace /> } />
            <Route path="/admin/payrollcalendar" element={isAuthenticated ? <Payrollcalendar logintype="admin" /> : <Navigate to="/" replace /> } />
            <Route path="/admin/paychexlogin" element={isAuthenticated ? <Paychexlogin logintype="admin"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/requests" element={isAuthenticated ? <Requestslist logintype="admin" apiurl="requestlist" /> : <Navigate to="/" replace /> } />
            <Route path="/admin/referralpolicy" element={isAuthenticated ? <Referalpolicylist logintype="admin"/> : <Navigate to="/" replace /> } />
            <Route path="/admin/suggestions" element={isAuthenticated ? <Suggestionlist logintype="admin" apiurl="suggestion"/> : <Navigate to="/" replace /> }/>
            <Route path="/admin/feedbacks" element={isAuthenticated ? <Feedbacklist logintype="admin" apiurl="feedback"/> : <Navigate to="/" replace /> }/>
        </Routes>
    </>
  )
}

export default AdminNav